import { Button, InputGroup } from 'react-bootstrap'
import { FormHandles, useField } from '@unform/core'
import { MutableRefObject, RefObject, useContext, useEffect, useRef, useState } from 'react'
import { Trash2, Upload } from 'react-feather'

import { FormInputFileSingleType } from '../../types/form/input-file'
import FormItemLayout from './form-item-layout'
import { InputRefProps } from '../../types/form/input'
import ValidationSchemaContext from '../../contexts/form-validation-context'
import cn from 'classnames'
import { emptyString } from '../../utils/string-utils'
import { humanFileSize } from '../../utils/file-utils-shared'
import { logger } from '../../services/logger-service'
import { useDropzone } from 'react-dropzone'

const FormInputFileSingleGeneric: FormInputFileSingleType = ({
    name,
    label,
    size,
    required,
    description,
    accept,
    maxSize = Infinity,
    minSize = 0,
    helpText = '',
    isEnabledClearFile = true,
    disabled,
    helpTextPlacement = 'tooltip',
    onFilesRejected,
    layoutClassName,
    ...rest
}) => {
    const inputRef = useRef<InputRefProps>(null)
    // const inputRefAux = useRef<HTMLInputElement>(null)
    const { fieldName, registerField, defaultValue = [], error } = useField(name)
    const [acceptedFiles, setAcceptedFiles] = useState<File[]>(defaultValue)
    const schemaContext = useContext(ValidationSchemaContext) // as schema is not required, we need check if it's present
    let formRef: MutableRefObject<FormHandles | null | undefined>
    if (schemaContext && schemaContext.FormRef) formRef = schemaContext.FormRef

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        open: openFileSelectorWindow,
        // inputRef,
        rootRef
    } = useDropzone({
        ...((accept && { accept }) || {}),
        multiple: false,
        disabled,
        maxSize,
        minSize,
        // Disable click, drag and keydown behavior (single file)
        noDrag: true,
        noClick: true,
        noKeyboard: true,
        onDrop: (accepted, rejected) => {
            if (rejected && rejected.length > 0) {
                logger.warn('FilesRejected', rejected)
                const messages: string[] = []
                for (let i = 0; i < rejected[0].errors.length; i++) {
                    const e = rejected[0].errors[i]
                    if (e.code === 'file-invalid-type')
                        messages.push(
                            `O tipo do arquivo é inválido, verifique os tipos aceitos ${
                                helpTextPlacement === 'inline'
                                    ? 'no texto de ajuda acima'
                                    : helpTextPlacement === 'tooltip'
                                    ? 'no ícone(?) acima'
                                    : ''
                            } e tente novamente.`
                        )
                    if (e.code === 'file-too-large')
                        messages.push(`O arquivo excede o tamanho máximo permitido de ${humanFileSize(maxSize)}`)
                }
                messages.length > 0 &&
                    formRef.current &&
                    formRef.current.setFieldError(fieldName, messages.join('\r\n'))
                onFilesRejected && onFilesRejected(fieldName, rejected)
            }
            if (inputRef.current && accepted !== undefined && accepted.length > 0) {
                inputRef.current.acceptedFiles = accepted
                inputRef.current.value = accepted[0].name
                setAcceptedFiles(accepted)
            }
        }
    })

    useEffect(() => {
        registerField<File | undefined | null>({
            name: fieldName,
            ref: inputRef,
            getValue: (ref: RefObject<InputRefProps>) => {
                logger.warn(`ActFormInputFileGeneric=>${fieldName}=>getValue=>`, ref.current?.acceptedFiles)
                const file =
                    (ref.current?.acceptedFiles &&
                        ref.current?.acceptedFiles?.length > 0 &&
                        ref.current?.acceptedFiles[0]) ||
                    undefined
                logger.warn(`ActFormInputFileGeneric=>${fieldName}=>getValue=>`, file)
                return file
            },
            clearValue: (ref: RefObject<InputRefProps>) => {
                logger.warn(`ActFormInputFileGeneric=>${fieldName}=>clearValue`)
                if (ref.current) {
                    ref.current.acceptedFiles = []
                    ref.current.value = emptyString
                }
                setAcceptedFiles([])
            },
            setValue: (ref: RefObject<InputRefProps>, value) => {
                logger.warn(`[ActFormInputFileGeneric]=>${fieldName}=>setValue=>`, value)
                logger.warn(`[ActFormInputFileGeneric:setValue:ref.current] => ${fieldName}`, ref.current)
                if (ref.current) {
                    if (value instanceof File) ref.current.acceptedFiles = [value]
                    ref.current.value = value?.name || emptyString
                }
                value && value instanceof File && setAcceptedFiles([value])
            }
        })
    }, [fieldName, inputRef, registerField])
    return (
        <FormItemLayout
            {...{ name: fieldName, label, required, description, helpText, helpTextPlacement, layoutClassName }}
        >
            <div
                {...getRootProps({
                    className: cn({
                        'is-invalid': error
                    })
                })}
            >
                <InputGroup key={`input-group-${fieldName}`}>
                    <input
                        defaultValue={defaultValue}
                        name={name}
                        ref={inputRef}
                        type="text"
                        readOnly
                        disabled
                        className={cn('form-control', {
                            'form-control-lg': size === 'large',
                            'form-control-sm': size === 'small',
                            'is-invalid': error
                        })}
                        {...rest}
                    />
                    <input {...getInputProps()} />

                    <Button
                        disabled={disabled}
                        key={`button-group-addon-upload-${fieldName}`}
                        color="primary"
                        title="Selecionar arquivo"
                        onClick={() => !disabled && openFileSelectorWindow()}
                    >
                        <Upload size={18} />
                    </Button>

                    {isEnabledClearFile && (
                        <Button
                            key={`button-group-addon-clear-${fieldName}`}
                            color="danger"
                            title="Remover Arquivo"
                            onClick={() => formRef.current?.clearField && formRef.current?.clearField(fieldName)}
                        >
                            <Trash2 size={18} />
                        </Button>
                    )}
                </InputGroup>
            </div>
        </FormItemLayout>
    )
}

export default FormInputFileSingleGeneric
