import React, { Component, PropsWithChildren } from 'react'

import { Container } from 'reactstrap'
import { NavMenu } from './nav-menu'
import { Outlet } from 'react-router-dom'

export const Layout: React.FC<PropsWithChildren> = () => {
    return (
        <div>
            <NavMenu />
            <Container>
                <Outlet />
            </Container>
        </div>
    )
}
