import { formatCapitalizedName, pad } from '../utils/string-utils'
import { formatDateToString, formatMothShortYear } from 'src/utils/datetime-utils'

import { CarteirinhaFrontType } from 'src/types/components/carteirinha'
import cn from 'classnames'
import { formatNumber } from 'src/utils/number-utils'

const CarteirinhaFront: CarteirinhaFrontType = ({ Model, ClassName }) => {
    return (
        <div className={`card card--front position-relative ${ClassName || ''}`}>
            {Model?.IdContrato && (
                <div className="card__number">
                    <span style={{ fontSize: '0.5em', display: 'block' }}>Num. Contrato</span>
                    {Model?.IdContrato.toString()}
                </div>
            )}
            <div className="row g-0 text-center">
                {Model?.DtAdesao && (
                    <div className="col">
                        <div className="card__expiry-date">
                            <span style={{ fontSize: '0.7em', display: 'block' }}>Data de Adesão</span>
                            {formatDateToString(new Date(Model.DtAdesao), 'dd/MM/yyyy')}
                        </div>
                    </div>
                )}
                {Model?.DtCarencia && (
                    <div className="col">
                        <div className="card__expiry-date">
                            <span style={{ fontSize: '0.7em', display: 'block' }}>Data de Carência</span>
                            {formatDateToString(new Date(Model.DtCarencia), 'dd/MM/yyyy')}
                        </div>
                    </div>
                )}
                {/* {Model?.DtValidade && (
                    <div className="col">
                        <div className="card__expiry-date">
                            <span style={{ fontSize: '0.7em', display: 'block' }}>Validade Carteira</span>
                            {formatDateToString(new Date(Model.DtValidade), 'dd/MM/yyyy')}
                        </div>
                    </div>
                )} */}
            </div>
            <div className="card__owner mb-2 text-center">{Model?.Nome}</div>
            <div className="card__owner">
                <span style={{ fontSize: '0.7em', display: 'block' }}>Origem</span>
                {formatCapitalizedName(Model?.OrigemContrato || '')}
            </div>

            {Model?.Plano && (
                <div id="ribbon-container">
                    <a
                        href="#"
                        id="ribbon"
                        className={cn({
                            'plano-ouro': Model.Plano.toUpperCase() === 'OURO',
                            'plano-prata': Model.Plano.toUpperCase() === 'PRATA',
                            'plano-basico': Model.Plano.toUpperCase() === 'BASICO',
                            'plano-simples': Model.Plano.toUpperCase() === 'SIMPLES'
                        })}
                    >
                        {Model.Plano}
                    </a>
                </div>
            )}
            <img src="images/logo-alpha-light.png" className="card__logo" alt="Lajao Logo" height={55} />
        </div>
    )
}

export default CarteirinhaFront
