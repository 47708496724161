import { AssociadoRole, DependenteRole } from '../constants/profile-roles'
import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { authRoute, loadProfiles, setCurrentProfile, tokenExpirationLeft } from '../constants/api-routes'

import { AuthContextType } from '../types/contexts/auth-context'
import { CredentialsKey } from '../constants/local-storage-keys'
import { DependenteModel } from '../types/models/dependente-model'
import { IAuthData } from '../types/models/i-auth-data'
import { IProfile } from '../types/models/i-profile'
import Swal from 'sweetalert2'
import { loginPage } from '../constants/local-routes'
import { useLocalStorage } from './use-local-storage'
import { useNavigate } from 'react-router-dom'

const authContext = createContext<AuthContextType>({} as AuthContextType)

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const ProvideAuth: React.FC<PropsWithChildren> = ({ children }) => {
    const auth = useProvideAuth()
    return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext)
}

// Provider hook that creates auth object and handles state
function useProvideAuth(): AuthContextType {
    const [authData, setAuthData] = useLocalStorage<IAuthData | null>(CredentialsKey)
    const navigate = useNavigate()
    // Wrap any Firebase methods we want to use making sure ...
    // ... to save the user to state.
    const signinAsync = async (username: string, password: string, contract: string) => {
        return new Promise<IAuthData>((resolve, reject) => {
            const payload = new FormData()
            payload.set(`username`, username)
            payload.set(`password`, password)
            payload.set(`contract`, contract)

            fetch(authRoute, { method: `post`, body: payload })
                .then(res => {
                    if (res.ok) return res.json()
                    else throw new Error()
                })
                .then((res: IAuthData) => {
                    setAuthData(res)
                    resolve(res)
                })
                .catch(e => {
                    Swal.fire({
                        title: 'Falha!',
                        text: 'Credenciais inválidas ou contrato inativo.',
                        icon: 'error',
                        confirmButtonText: 'Fechar',
                        confirmButtonColor: '#38b554'
                    })
                    reject(e)
                })
        })
    }
    const signupAsync = async (username: string, email: string, password: string) => {
        return new Promise<void>((resolve, reject) => {
            setTimeout(resolve, 3000)
        })
    }
    const signoutAsync = async () => {
        return new Promise<void>((resolve, reject) => {
            setTimeout(resolve, 3000)
        })
    }
    const sendPasswordResetEmailAsync = async (email: string) => {
        return new Promise<void>((resolve, reject) => {
            setTimeout(resolve, 3000)
        })
    }
    const confirmPasswordResetAsync = async (code: string, password: string) => {
        return new Promise<void>((resolve, reject) => {
            setTimeout(resolve, 3000)
        })
    }

    const setCurrentProfileAsync = async (profileId: number) => {
        return new Promise<void>((resolve, reject) => {
            const payload = new FormData()
            payload.set('profileId', profileId.toString())
            fetch(setCurrentProfile, {
                method: 'post',
                body: payload,
                headers: { Authorization: `Bearer ${authData?.Token}` }
            })
                .then(res => {
                    if (res.ok) return res.json()
                    else throw new Error('ERR_RESPONSE')
                })
                .then(res => {
                    setAuthData(res as IAuthData)
                    Swal.fire({
                        title: 'Concluído!',
                        text: 'Perfil definido com sucesso!',
                        icon: 'success',
                        confirmButtonText: 'Fechar',
                        confirmButtonColor: '#38b554'
                    }).then(result => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            resolve()
                        }
                    })
                })
                .catch(e => {
                    Swal.fire({
                        title: 'Error!',
                        text: 'Falha ao definir o perfil!',
                        icon: 'error',
                        confirmButtonText: 'Fechar',
                        confirmButtonColor: '#38b554'
                    }).then(result => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            reject()
                        }
                    })
                })
        })
    }

    const loadProfilesAsync = async () => {
        return new Promise<DependenteModel[]>((resolve, reject) => {
            if (authData && authData.Dependents) resolve(authData.Dependents)
            else
                fetch(loadProfiles, {
                    method: 'get',
                    headers: { Authorization: `Bearer ${authData?.Token}` }
                })
                    .then(res => {
                        if (res.ok) return res.json()
                        else throw new Error('ERR_RESPONSE')
                    })
                    .then(resolve)
                    .catch(reject)
        })
    }

    const logoutAsync = async () => {
        return new Promise<void>((resolve, reject) => {
            setAuthData(null as unknown as IAuthData)
            navigate(loginPage)
        })
    }

    const getCurrentProfileAsync = async () => {
        return new Promise<IProfile | null | undefined>((resolve, reject) => {
            if (!authData || !authData.ProfileId) reject('AUTH_DATA_NOT_PRESENT')
            else {
                if (authData.ProfileId === -1)
                    resolve({
                        Id: -1,
                        Name: authData.User.Nome,
                        Role: AssociadoRole,
                        Genre: authData.User.Sexo
                    } as IProfile)
                else {
                    resolve(
                        authData.Dependents.filter(x => x.Id === authData.ProfileId)
                            .map(
                                x =>
                                    ({
                                        Id: x.Id,
                                        Name: x.Nome,
                                        Role: DependenteRole,
                                        Genre: x.Sexo
                                    } as IProfile)
                            )
                            .find(x => true)
                    )
                }
            }
        })
    }

    useEffect(() => {
        if (authData) {
            //if user is present fetch the expiration time left
            fetch(tokenExpirationLeft, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${authData.Token}`
                }
            })
                .then(async res => {
                    if (res.ok) return parseInt(await res.text(), 10)
                    else throw new Error('INVALID_STATUSCODE: ' + res.status + ', Reason: ' + (await res.text()))
                })
                .then(millisecondsLeft => {
                    console.warn('Token milliseconds left', millisecondsLeft)
                })
                .catch(e => {
                    let reason = 'UNKNOWN'
                    if (e instanceof Error) {
                        reason = e.message
                    }
                    setAuthData(null)
                    navigate(loginPage)
                    console.warn('ERR_GET_TOKEN_EXPIRATION_LEFT: ' + reason)
                })
        }
    }, [authData])

    // Return the user object and auth methods
    return {
        AuthData: authData,
        SignInAsync: signinAsync,
        SignUpAsync: signupAsync,
        SignOutAsync: signoutAsync,
        SendPasswordResetEmailAsync: sendPasswordResetEmailAsync,
        ConfirmPasswordResetAsync: confirmPasswordResetAsync,
        SetCurrentProfileAsync: setCurrentProfileAsync,
        GetCurrentProfileAsync: getCurrentProfileAsync,
        LoadProfilesAsync: loadProfilesAsync,
        LogoutAsync: logoutAsync
    }
}
