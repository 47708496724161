export const onlyNumbers = (str: string) => str.replace(/[^0-9]/g, '')
export const formatSureName = (name: string) => capitalizeFirstLetter(name.split(' ').slice(-1)[0])
export const formatFirstName = (name: string) => capitalizeFirstLetter(name.split(' ')[0])
export const formatShortName = (name: string) => `${formatFirstName(name)} ${formatSureName(name)}`
export const capitalizeFirstLetter = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}
export const formatCapitalizedName = (name: string, ignoreLength = false) =>
    name
        .split(/\s+/)
        .map(x => (x.length > 3 || ignoreLength ? capitalizeFirstLetter(x) : x.toLowerCase()))
        .join(' ')
export const pad = (content: unknown, targetLength: number, padString: string) =>
    String(content).padStart(targetLength, padString)

export const formatFirstAndLastName = (fullName: string) => {
    const capitalizedFullName = formatCapitalizedName(fullName)
    const firstName = capitalizedFullName.split(' ').slice(0, 1).join(' ')
    const lastName = capitalizedFullName.split(' ').slice(-1).join(' ')
    return `${firstName} ${lastName}`.trim()
}

// Verifica se uma string é uma data. ex: '2017-01-02T00:00:00.000-02:00'
export function stringIsDate(value: string) {
    const dateRegex =
        /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])T(0[0-9]|1[0-9]|2[1-4]):(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9]))/
    return dateRegex.test(value)
}

export const parseCdsFromString = (
    cds: string | number[] | number | null | undefined,
    separator = ','
): number[] | null => {
    if (cds === null || cds === undefined) return null
    if (typeof cds === 'number') return [cds]
    if (Array.isArray(cds)) return cds
    const parsed = cds
        .replace(/\s/g, '')
        .split(separator)
        .map(item => Number(item))
        .filter(item => typeof item === 'number' && !isNaN(item) && item > 0)
    return parsed
}
/**
 * Applies the parsing of classes removing any duplicate entries (case-sensitive)
 * @param  {string|null|undefined} klasses
 */

export const distinctClassNames = (klasses: string | null | undefined): string => {
    if (typeof klasses !== 'string') return ''
    return Array.from(
        new Set(
            klasses
                .trim()
                .split(' ')
                .filter(x => x !== '')
        )
    ).join(' ')
}

export const truncate = (content: string, max: number) =>
    content?.length > max && max - 3 > 0 ? `${content.slice(0, max - 3)}...` : content

export const escapeRegExp = (string: string) => {
    return string.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export const emptyString = ''

export const formataTelefone = (nuTelefone: string, nuPrefixo?: string) => {
    if (typeof nuTelefone !== 'string') return ''
    if (nuTelefone.length < 7) return nuTelefone

    return `${nuPrefixo && `(${nuPrefixo}) `} ${nuTelefone.substring(0, nuTelefone.length - 4)}-${nuTelefone.slice(-4)}`
}

export const composeEndereco = (...parts: Array<string | undefined | null>) =>
    parts.filter(p => p !== '' && p !== null && p !== undefined).join(', ')

export function isNullOrEmpty(value: string | undefined) {
    return !value
}

export const toFloat = (v: string | null | undefined) =>
    typeof v === 'number' ? v : typeof v === 'string' && !isNaN(parseFloat(v)) ? parseFloat(v) : undefined

export const toInt = (v: string | null | undefined) =>
    typeof v === 'number' ? v : typeof v === 'string' && !isNaN(parseInt(v)) ? parseInt(v) : undefined

export const toDate = (str: string, format: string) => {
    try {
        const MM = parseInt(str.substring(0, 2), 10)
        const dd = parseInt(str.substring(3, 5), 10)
        const yyyy = parseInt(str.substring(6, 10), 10)
        const hh = parseInt(str.substring(11, 13), 10)
        const mm = parseInt(str.substring(14, 16), 10)
        const ss = parseInt(str.substring(17, 19), 10)
        let output

        if (format === 'dd/MM/yyyy hh:mm') {
            output = new Date(yyyy, dd - 1, MM, hh, mm)
        }
        if (format === 'dd/MM/yyyy hh:mm:ss') {
            output = new Date(yyyy, dd - 1, MM, hh, mm, ss)
        }
        if (format === 'dd/MM/yyyy') {
            output = new Date(yyyy, dd - 1, MM)
        }

        /* if ((date_ref.getTimezoneOffset() / 60) - (output.getTimezoneOffset() / 60) > 0)
                    output.setHours(output.getHours() + 1); */

        return output
    } catch (e) {
        return null
    }
}

export const toTitleCase = (str: string) => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
}

export function getSlug(desc: string) {
    if (!desc || typeof desc !== 'string') return ''

    let retorno = desc.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    retorno = retorno
        .toLowerCase()
        .trim()
        .replace(/(\s)|(\/)/g, '-')
    retorno = retorno.replace(/[^A-Za-z0-9\\-]/g, '')
    return retorno
}

export const addForwardSlashIfNotPresent = (v: string) => (`${v}`.endsWith('/') ? `${v}` : `${v}/`)
