import IMask from 'imask'
import { pad } from '../utils/string-utils'

export const ptBrPrefixPhone: IMask.AnyMaskedOptions = {
    mask: '00'
}

export const ptBrTelephone: IMask.AnyMaskedOptions = {
    mask: '0000-0000'
}

export const ptBrCellphone: IMask.AnyMaskedOptions = {
    mask: '(00)[0]00000000'
}

export const ptBrPhone: IMask.AnyMaskedOptions = {
    mask: '(00)0000-0000'
}

export const ptBrPostalCode: IMask.AnyMaskedOptions = {
    mask: '00000-0000'
}

export const ptBRCurrency: IMask.AnyMaskedOptions = {
    mask: 'R$ num',
    blocks: {
        num: {
            mask: Number,
            padFractionalZeros: true,
            thousandsSeparator: '.',
            radix: ',',
            mapToRadix: ['.']
        }
    }
}

export const ptBrStateAbbr: IMask.AnyMaskedOptions = {
    mask: 'aa',
    prepare: function (str: string) {
        return str.toUpperCase()
    }
}

export const ptBRWithOutCurrency: IMask.AnyMaskedOptions = {
    mask: 'num',
    blocks: {
        num: {
            mask: Number,
            padFractionalZeros: true,
            thousandsSeparator: '.',
            radix: ',',
            mapToRadix: ['.']
        }
    }
}

export const ptBRDate: IMask.MaskedDateOptions = {
    // commit: (v: string) => console.log('accept', v),
    mask: Date, // enable date mask
    // other options are optional
    pattern: 'd/`m/`Y', // Pattern mask with defined blocks, default is 'd{.}`m{.}`Y'
    // you can provide your own blocks definitions, default blocks for date mask are:
    blocks: {
        d: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 31,
            maxLength: 2
        },
        m: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
            maxLength: 2
        },
        Y: {
            mask: IMask.MaskedRange,
            from: 1900,
            to: 9999
        }
    },
    // define date -> str convertion
    format: function (date: Date) {
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()

        // if (day < 10) day = '0' + day
        // if (month < 10) month = '0' + month

        return [pad(`${day}`, 2, '0'), pad(`${month}`, 2, '0'), pad(`${year}`, 4, '0')].join('/')
    },
    // define str -> date convertion
    parse: function (str: string) {
        const [day, month, yearn] = str.split('/').map(member => parseInt(member, 10))
        return new Date(yearn, month - 1, day)
    },

    // optional interval options
    min: new Date(1900, 0, 1), // defaults to `1900-01-01`
    max: new Date(9999, 0, 1), // defaults to `9999-01-01`

    autofix: true, // defaults to `false`, see details

    // also Pattern options can be set
    lazy: false,

    // and other common options
    overwrite: false // defaults to `false`
}
