import {
    carteirinhaPage,
    mensalidadeListPage,
    selectProfilePage,
    solicitarAtendimentoMedicoPage
} from './constants/local-routes'

import { CarteirinhaPage } from './pages/carteirinha/index'
import { Home } from './pages/home/index'
import { MensalidadeListPage } from './pages/mensalidades/index'

import SelectProfilePage from './pages/select-profile/index'
import { SolicitarAtendimentoMedicoPage } from './pages/atendimento/index'

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: carteirinhaPage,
        element: <CarteirinhaPage />
    },
    {
        path: mensalidadeListPage,
        element: <MensalidadeListPage />
    },
    {
        path: selectProfilePage,
        element: <SelectProfilePage />
    },
    {
        path: solicitarAtendimentoMedicoPage,
        element: <SolicitarAtendimentoMedicoPage />
    }
]

export default AppRoutes
