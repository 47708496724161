import './solicitar-atendimento-medico.scss'

import React, { FormEvent, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { atendimentoMedicoConsultas, atendimentoMedicoExames, atendimentoMedicoSubmit } from 'src/constants/api-routes'
import {
    compressedFile7Zip,
    compressedFileRar,
    compressedFileZip,
    documentPdf,
    images
} from 'src/constants/accepted-file-type'
import { ptBrCellphone, ptBrPhone } from '../../constants/imask-options-constants'

import { AtendimentoMedico } from 'src/models/atendimento-medico'
import { AtendimentoMedicoSchema } from 'src/validation/schemas/atendimento-medico'
import { ExtendedFormHandles } from 'src/types/3rd-party/unform/extensions'
import { Form } from 'src/components/form/form'
import FormCheckbox from 'src/components/form/form-checkbox'
import { FormHelpers } from '@unform/core'
import FormInputFileSingleGeneric from 'src/components/form/form-input-file-generic-single'
import FormInputMaskedAlt from 'src/components/form/form-input-masked-alt'
import FormInputRaw from '../../components/form/form-input-raw'
import FormSelect from 'src/components/form/form-select'
import FormTextArea from 'src/components/form/form-textarea'
//import FormInputMaskedAlt from ../../components/form/form-input-raw'
import Helmet from 'react-helmet'
import { LoadingModal } from 'src/components/loading-modal'
import { SelectOptionModel } from 'src/models/select-option'
import Swal from 'sweetalert2'
import { mbInBytes } from 'src/utils/file-utils-shared'
import { solicitarAtendimentoMedicoPage } from 'src/constants/local-routes'
import { titlePageTemplate } from '../../constants/strings'
import { useAuth } from 'src/hooks/use-auth'

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
    },
    confirmButtonColor: '#38b554',
    buttonsStyling: false
})

export const SolicitarAtendimentoMedicoPage: React.FC<PropsWithChildren> = () => {
    const tiposAtendimentoMap = { consulta: '1', exame: '2' }
    const [isLoadingOptions, setIsLoadingOptions] = useState(false)
    const [tipoAtendimento, setTipoAtendimento] = useState<SelectOptionModel | null>(null)
    const [exames, setExames] = useState<Array<SelectOptionModel> | undefined>()
    const [consultas, setConsultas] = useState<Array<SelectOptionModel> | undefined>()
    const [isLoading, setIsLoading] = useState(false)
    const [isExecutingSubmit, setIsExecutingSubmit] = useState<boolean>(false)
    const [hasExame, setHasExame] = useState<boolean>(false)
    const ptBrTelephone: IMask.AnyMaskedOptions = {
        mask: '0000-0000'
    }
    const formRef = React.useRef<ExtendedFormHandles | null>(null)
    const authContext = useAuth()

    const [tipoTelContato, setTipoTelContato] = useState<'cel' | 'tel'>('cel')
    const proceedSubmit = (model: Partial<AtendimentoMedico>, { reset }: FormHelpers) => {
        // if validation is ok, we will reach here, so we can post it to backend
        const formData = new FormData()
        if (model.AnexoPedidoMedico) {
            formData.set('AnexoPedidoMedico', model.AnexoPedidoMedico)
        }

        formData.set('IsConsulta', (model.IsConsulta && 'True') || 'False')
        formData.set('IsExame', (model.IsExame && 'True') || 'False')
        formData.set('TelContato', model.TelContato || '')
        // formData.set('NomeMedicoSolicitante', model.NomeMedicoSolicitante || '')
        // formData.set('CRMMedicoSolicitante', model.CRMMedicoSolicitante || '')
        // formData.set('JustificativaMedica', model.JustificativaMedica || '')
        formData.set('Observacoes', model.Observacoes || '')

        setIsLoading(true)
        fetch(atendimentoMedicoSubmit, {
            method: 'post',
            body: formData,
            headers: {
                Authorization: `Bearer ${authContext.AuthData?.Token}`
            }
        })
            .then(async r => {
                if (r.ok) {
                    swalWithBootstrapButtons
                        .fire('Enviado!', 'Sua solicitação foi enviada com sucesso.', 'success')
                        .then(() => {
                            reset && reset()
                        })
                } else {
                    throw new Error(await r.text())
                }
            })
            .catch((e: Error) => {
                console.warn('error', e)
                swalWithBootstrapButtons.fire(
                    'Falha',
                    'Não foi possível concluir a operação de envio. Entre em contato com o administrador do sistema',
                    'error'
                )
            })
            .finally(() => setIsLoading(false))
    }

    const handleSubmit = (model: Partial<AtendimentoMedico>, helpers: FormHelpers, event?: FormEvent) => {
        if (isLoading) return

        swalWithBootstrapButtons
            .fire({
                title: 'Confirma o envio?',
                text: 'Você está prestes a enviar uma solicitação de atendimento!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim, envie!',
                cancelButtonText: 'Não, cancele!',
                reverseButtons: true
            })
            .then(result => {
                if (result.isConfirmed) {
                    proceedSubmit(model, helpers)
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    swalWithBootstrapButtons.fire('Cancelado', 'A operação de envio foi cancelada.', 'error')
                }
            })
    }
    // const tiposAtendimento: Array<SelectOptionModel> = [
    //     { key: '1', value: '1', label: 'Consulta' },
    //     { key: '2', value: '2', label: 'Exame' }
    // ]

    // useEffect(() => {
    //     switch (tipoAtendimento?.value) {
    //         case tiposAtendimentoMap.consulta:
    //             if (consultas === undefined) {
    //                 setIsLoadingOptions(true)
    //                 fetch(atendimentoMedicoConsultas, {
    //                     headers: {
    //                         Authorization: `Bearer ${authContext.AuthData?.Token}`
    //                     }
    //                 })
    //                     .then(r => {
    //                         if (r.ok) return r.json()
    //                         throw new Error()
    //                     })
    //                     .then((r: Array<SelectOptionModel>) => {
    //                         console.log('consultas', r)
    //                         setConsultas(r)
    //                     })
    //                     .finally(() => setIsLoadingOptions(false))
    //             }
    //             break
    //         case tiposAtendimentoMap.exame:
    //             if (exames === undefined) {
    //                 setIsLoadingOptions(true)
    //                 fetch(atendimentoMedicoExames, {
    //                     headers: {
    //                         Authorization: `Bearer ${authContext.AuthData?.Token}`
    //                     }
    //                 })
    //                     .then(r => {
    //                         if (r.ok) return r.json()
    //                         throw new Error()
    //                     })
    //                     .then((r: Array<SelectOptionModel>) => {
    //                         console.log('exames', r)
    //                         setExames(r)
    //                     })
    //                     .finally(() => setIsLoadingOptions(false))
    //             }

    //             break
    //     }
    //     console.log('mudou', tipoAtendimento)
    // }, [tipoAtendimento])

    const handleTipoTelContato = useCallback(
        (tipo: 'tel' | 'cel') => {
            if (process.env.NODE_ENV !== 'production') {
                console.log('mudou' + tipo)
            }
            setTipoTelContato(tipo)
        },
        [tipoTelContato]
    )
    return (
        <div className="atendimento-medico-page">
            <Helmet>
                <title>{titlePageTemplate.replace('{0}', 'Atendimento médico')}</title>
            </Helmet>
            <LoadingModal IsOpen={isLoading} />
            <div className="row d-flex justify-content-center form-atendimento">
                <div className="col-xl-7 col-lg-8 col-md-9 col-11 text-center">
                    <div className="card">
                        <h5 className="text-center mb-4">
                            Realize o agendamento do seu atendimento médico através do formulário abaixo.
                        </h5>
                        <div className="container">
                            <Form<Partial<AtendimentoMedico>>
                                Schema={AtendimentoMedicoSchema(hasExame)}
                                onSubmit={handleSubmit}
                                InnerRef={formRef}
                                className="form-card text-start"
                            >
                                <div className="row justify-content-between text-left">
                                    <div className="col-sm-12 flex-column d-flex is-invalid">
                                        <label htmlFor="inputAddress" className="form-label">
                                            Tipo de atendimento <span style={{ color: '#F00' }}>*</span>
                                        </label>
                                        <FormCheckbox name="IsConsulta" label="Consulta" value="Consulta" />
                                        <FormCheckbox
                                            name="IsExame"
                                            label="Exame"
                                            value="Exame"
                                            onChange={e => setHasExame(e)}
                                        />
                                    </div>
                                    <div className="invalid-feedback" style={{ color: '#000' }}>
                                        <span style={{ color: '#F00' }}>*</span> É necessário selecionar pelo menos um
                                        tipo de atendimento.
                                    </div>
                                </div>
                                {/* <div className="row justify-content-between text-left">
                                    <FormSelect<SelectOptionModel>
                                        layoutClassName="col-sm-12 flex-column d-flex"
                                        tabIndex={1}
                                        valueSelector={e => e[0] && e[0].label}
                                        label="Tipo de atendimento"
                                        name="TipoDeAtendimento"
                                        options={tiposAtendimento}
                                        onChange={e => {
                                            setTipoAtendimento(e)
                                            formRef.current?.clearField('Procedimento')
                                        }}
                                    />
                                </div> */}
                                {/* <div className="row justify-content-between text-left">
                                    <FormSelect<SelectOptionModel>
                                        layoutClassName="col-sm-12 flex-column d-flex"
                                        tabIndex={1}
                                        valueSelector={e => e[0] && e[0].label}
                                        label="Procedimento"
                                        name="Procedimento"
                                        isLoading={isLoadingOptions}
                                        options={
                                            (tipoAtendimento?.key === tiposAtendimentoMap.consulta
                                                ? consultas
                                                : exames) || []
                                        }
                                    />
                                </div>
                                <div className="row justify-content-between text-left">
                                    <FormInputRaw
                                        layoutClassName="col-sm-12 flex-column d-flex"
                                        name="NomeMedicoSolicitante"
                                        label="Nome do médico solicitante"
                                    />
                                </div>

                                <div className="row justify-content-between text-left">
                                    <FormInputRaw
                                        layoutClassName="col-sm-12 flex-column d-flex"
                                        name="CRMMedicoSolicitante"
                                        label="CRM do médico Solicitante"
                                    />
                                </div> */}
                                <div className="row justify-content-between text-left">
                                    <div className="col-sm-12 flex-column d-flex">
                                        <FormInputMaskedAlt
                                            maskOptions={ptBrCellphone}
                                            layoutClassName="col-sm-12 flex-column d-flex"
                                            name="TelContato"
                                            label="Tel. Contato"
                                        />
                                    </div>
                                </div>
                                <div className="row justify-content-between text-left">
                                    <FormInputFileSingleGeneric
                                        maxSize={mbInBytes(10)}
                                        accept={{
                                            ...images,
                                            ...compressedFileZip,
                                            ...compressedFileRar,
                                            ...compressedFile7Zip,
                                            ...documentPdf
                                        }}
                                        layoutClassName="col-sm-12 flex-column"
                                        name="AnexoPedidoMedico"
                                        label="Pedido médico"
                                        multiple
                                        helpText="* Formatos de arquivo aceitos: PDF, JPG, JPEG, GIF, PNG, RAR e ZIP. Tamanho máximo permitido: 10 MB. "
                                        helpTextPlacement="tooltip"
                                    />
                                </div>
                                <div className="row justify-content-between text-left">
                                    <FormTextArea
                                        layoutClassName="col-sm-12 flex-column d-flex"
                                        name="Observacoes"
                                        label="Observações"
                                    />
                                </div>
                                <div className="row justify-content-end text-center">
                                    <div className="d-grid gap-2 d-md-block">
                                        <button type="submit" className="btn btn-primary btn-lg">
                                            Enviar
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
