import { formatCapitalizedName, pad } from '../utils/string-utils'
import { formatDateToString, formatMothShortYear } from 'src/utils/datetime-utils'
import { useEffect, useState } from 'react'

import { CarteirinhaFrontType } from 'src/types/components/carteirinha'
import { DependenteModel } from 'src/types/models/dependente-model'
import cn from 'classnames'
import { formatNumber } from 'src/utils/number-utils'
import { profile } from 'console'

const CarteirinhaFrontDependent: CarteirinhaFrontType = ({ Profile, Model, Dependentes, ClassName }) => {
    const [currentDependent, setCurrentDependent] = useState<DependenteModel | null>(null)
    console.log('Dependentes', Dependentes)

    useEffect(() => {
        const found = Dependentes?.find(x => x.Id === Profile?.Id)
        if (found) setCurrentDependent(found)
    }, [Dependentes])

    return (
        <div className={`card card--front position-relative ${ClassName || ''}`}>
            {Model?.IdContrato && (
                <div className="card__number">
                    <span style={{ fontSize: '0.5em', display: 'block' }}>Num. Contrato</span>
                    {Model?.IdContrato.toString()}
                </div>
            )}
            <div className="row g-0 text-center">
                {currentDependent?.DtAdesao && (
                    <div className="col">
                        <div className="card__expiry-date">
                            <span style={{ fontSize: '0.7em', display: 'block' }}>Data de Adesão</span>
                            {formatDateToString(new Date(currentDependent.DtAdesao), 'dd/MM/yyyy')}
                        </div>
                    </div>
                )}
                {currentDependent?.Carencia && (
                    <div className="col">
                        <div className="card__expiry-date">
                            <span style={{ fontSize: '0.7em', display: 'block' }}>Data de Carência</span>
                            {formatDateToString(new Date(currentDependent.Carencia), 'dd/MM/yyyy')}
                        </div>
                    </div>
                )}
                {/* {Model?.DtValidade && (
                    <div className="col">
                        <div className="card__expiry-date">
                            <span style={{ fontSize: '0.7em', display: 'block' }}>Validade Carteira</span>
                            {formatDateToString(Model?.DtValidade || '', 'dd/MM/yyyy')}
                        </div>
                    </div>
                )} */}
            </div>
            <div className="card__owner mb-2 text-center">{Profile?.Name}</div>
            <div className="row">
                <div className="col">
                    <div className="card__owner">
                        <span style={{ fontSize: '0.7em', display: 'block' }}>Parentesco</span>
                        {formatCapitalizedName(currentDependent?.Parentesco || '', true)}
                    </div>
                </div>
                <div className="col">
                    <div className="card__owner">
                        <span style={{ fontSize: '0.7em', display: 'block', textAlign: 'right' }}>Dt. Nascimento</span>
                        <span style={{ display: 'block', textAlign: 'right' }}>
                            {currentDependent?.DtNascimento &&
                                formatDateToString(new Date(currentDependent.DtNascimento), 'dd/MM/yyyy')}
                        </span>
                    </div>
                </div>
            </div>

            {Model?.Plano && (
                <div id="ribbon-container">
                    <a
                        href="#"
                        id="ribbon"
                        className={cn({
                            'plano-ouro': Model.Plano.toUpperCase() === 'OURO',
                            'plano-prata': Model.Plano.toUpperCase() === 'PRATA',
                            'plano-basico': Model.Plano.toUpperCase() === 'BASICO',
                            'plano-simples': Model.Plano.toUpperCase() === 'SIMPLES'
                        })}
                    >
                        {Model.Plano}
                    </a>
                </div>
            )}
            <img src="images/logo-alpha-light.png" className="card__logo" alt="Lajao Logo" height={55} />
        </div>
    )
}

export default CarteirinhaFrontDependent
