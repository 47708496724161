import * as Yup from 'yup'

import { FormHandles, FormHelpers, SubmitHandler, FormProps as UnformFormProps } from '@unform/core'
import { FormProps, FormWithRefProps } from '../../types/form/form'
import React, { FormEvent, PropsWithChildren, forwardRef, useImperativeHandle, useRef } from 'react'

import { ExtendedFormHandles } from '../../types/3rd-party/unform/extensions'
import { FormValidationContextProvider } from '../../contexts/form-validation-context'
import { Form as UnformForm } from '@unform/web'
import { logger } from '../../services/logger-service'

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const FormInner = <TData extends unknown>(
    { children, onSubmit, Schema, ...rest }: PropsWithChildren<FormProps<TData>>,
    externalFormRef: React.ForwardedRef<ExtendedFormHandles>
) => {
    const innerFormRef = useRef<FormHandles>(null)
    const internalHandleReset = () => {
        console.warn('internalHandleReset:invoked')
        // Remove all previous errors
        innerFormRef.current?.setErrors({})
        innerFormRef.current?.reset()
    }

    // we are binding the external ref with the internal(from Unform library)
    useImperativeHandle<unknown, ExtendedFormHandles>(
        externalFormRef,
        () =>
            ({
                ...(innerFormRef.current || {}),
                resetDataAndErrors: internalHandleReset
            } as ExtendedFormHandles)
    )

    const internalHandleSubmit: SubmitHandler<TData> = async function (
        data: TData,
        helpers: FormHelpers,
        event?: FormEvent
    ) {
        try {
            if (process.env.NODE_ENV !== 'production') {
                console.log('currentData', data)
            }
            const parsedData =
                (Schema !== undefined &&
                    Schema !== null &&
                    ((await Schema.validate(data, {
                        stripUnknown: true,
                        strict: false,
                        abortEarly: false,
                        context: { currentData: data }
                    })) as TData)) ||
                data // we are checking if the validation schema was provided, before invoke the validation method.
            if (process.env.NODE_ENV !== 'production') {
                console.log('currentData', data)
            }
            // cleaning all previous errors
            innerFormRef.current?.setErrors({})

            // forwarding the form data to first class component
            onSubmit(parsedData, helpers, event)
        } catch (err) {
            const validationErrors: Record<string, string> = {}
            if (err instanceof Yup.ValidationError) {
                err.inner.forEach(error => {
                    if (error.path) validationErrors[error.path] = error.message
                })

                innerFormRef.current && innerFormRef.current.setErrors(validationErrors)
                if (process.env.NODE_ENV !== 'production') {
                    console.log('validationErrors', validationErrors)
                }
            } else if (err instanceof Error) logger.error(err.message)
        }
    }

    return (
        <FormValidationContextProvider value={{ Schema, FormRef: innerFormRef }}>
            <UnformForm onSubmit={internalHandleSubmit} {...rest} ref={innerFormRef}>
                {children}
            </UnformForm>
        </FormValidationContextProvider>
    )
}

const FormWithRef = forwardRef(FormInner)
export function Form<T>({ InnerRef, onSubmit, ...props }: FormWithRefProps<T>) {
    return <FormWithRef onSubmit={onSubmit as unknown as SubmitHandler<unknown>} {...props} ref={InnerRef} />
}

export default Form
