import './nav-menu.css'

import { Collapse, NavItem, NavLink, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap'
import { Link, NavLink as NavLinkRouter } from 'react-router-dom'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import {
    carteirinhaPage,
    homePage,
    mensalidadeListPage,
    selectProfilePage,
    solicitarAtendimentoMedicoPage
} from '../constants/local-routes'

import { AssociadoModel } from 'src/types/models/associado-model'
import { IProfile } from 'src/types/models/i-profile'
import { NavDropdown } from 'react-bootstrap'
import { useAuth } from '../hooks/use-auth'

export const NavMenu: React.FC<PropsWithChildren> = () => {
    const authContext = useAuth()
    const [collapsed, setCollapsed] = useState(true)
    const toggleNavbar = () => {
        setCollapsed(prev => !prev)
    }
    // This styling will be applied to a <NavLink> when the
    // route that it links to is currently selected.
    const activeStyle = {
        textDecoration: 'underline'
    }

    const [associado, setAssociado] = useState<AssociadoModel | null | undefined>()
    const [profile, setProfile] = useState<IProfile | null | undefined>()
    const [nuOverdue, setNuOverdue] = useState<number | undefined>()

    useEffect(() => {
        const doAsync = async () => {
            authContext?.AuthData && authContext?.AuthData?.User && setAssociado(authContext?.AuthData.User)
            const currentProfile = await authContext.GetCurrentProfileAsync()

            setProfile(currentProfile)
            setNuOverdue(authContext?.AuthData?.NuOverdue)
        }
        doAsync()
    }, [authContext])

    return (
        <header>
            <Navbar
                className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
                container
                light
            >
                <NavbarBrand tag={Link} to="/">
                    <img src="/images/logo-alpha.png" width="150" alt="" className="d-inline-block align-text-top" />
                </NavbarBrand>
                <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                    <ul className="navbar-nav flex-grow">
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to={homePage}>
                                Home
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to={carteirinhaPage}>
                                Carteirinha
                            </NavLink>
                        </NavItem>
                        {(nuOverdue == 0 || (nuOverdue && nuOverdue < 3)) && (
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to={solicitarAtendimentoMedicoPage}>
                                    Atendimento
                                </NavLink>
                            </NavItem>
                        )}

                        <NavItem>
                            <NavLink // removed bootstrap attribute
                                tag={Link}
                                className="text-dark"
                                to={mensalidadeListPage}
                            >
                                Mensalidades
                            </NavLink>
                        </NavItem>
                        <NavDropdown
                            className="no-arrow"
                            title={<span className="fa fa-user-circle-o fa-5"></span>}
                            id="nav-dropdown"
                        >
                            {authContext?.AuthData?.Dependents && authContext?.AuthData?.Dependents.length > 0 && (
                                /*authContext.AuthData?.User.NuOverdue < 3  && */ <NavDropdown.Item eventKey="4.3">
                                    <NavLinkRouter
                                        style={({ isActive }) => (isActive ? activeStyle : undefined)}
                                        to={selectProfilePage}
                                    >
                                        Perfis
                                    </NavLinkRouter>
                                </NavDropdown.Item>
                            )}
                            <NavDropdown.Divider />
                            <NavDropdown.Item eventKey="4.4" onClick={() => authContext.LogoutAsync()}>
                                Sair
                            </NavDropdown.Item>
                        </NavDropdown>
                    </ul>
                </Collapse>
            </Navbar>
        </header>
    )
}
