import './mensalidades.scss'

import React, { PropsWithChildren, useEffect, useState } from 'react'
import { mensalidadeProcessPayment, mensalidadesListPaged } from '../../constants/api-routes'

import { Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { LoadingModal } from '../../components/loading-modal'
import { MensalidadeModel } from '../../types/models/mensalidade-model'
import { ProcessPaymentReturnType } from '../../types/api-response/mensalidade'
import Swal from 'sweetalert2'
import { formatCurrencyBR } from '../../utils/number-utils'
import { formatDateInBrazilianStandard } from '../../utils/datetime-utils'
import { titlePageTemplate } from '../../constants/strings'
import { useAuth } from '../../hooks/use-auth'

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
    },
    confirmButtonColor: '#38b554',
    buttonsStyling: false
})

export const MensalidadeListPage: React.FC<PropsWithChildren> = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [state, setState] = useState<{
        data: MensalidadeModel[]
        loading: boolean
    }>({
        data: [],
        loading: true
    })
    const authContext = useAuth()

    const populateData = async () => {
        const token = authContext.AuthData?.Token
        const response = await fetch(mensalidadesListPaged(0), {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        const data: MensalidadeModel[] = await response.json()
        if (process.env.NODE_ENV !== 'production') {
            console.log('data', data)
        }
        setState({ data, loading: false })
    }

    const handlePayment = (item: MensalidadeModel) => {
        Swal.fire({
            title: 'Confirma o processamento do pagamento?',
            text: 'O pagamento será realizado através da plataforma de pagamento Mercado Pago, você será redirecionado(a) para a tela de pagamento, e ao finalizar será redirecionado de volta para cá.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim!',
            confirmButtonColor: '#38b554'
        }).then(result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const token = authContext.AuthData?.Token
                setIsOpen(true)
                fetch(mensalidadeProcessPayment(item.IdMensalidade.toString()), {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                    .then(r => {
                        if (r.ok) return r.json()
                        throw new Error('INVALID_RESPONSE')
                    })
                    .then((d: ProcessPaymentReturnType) => (window.location.href = d.urlCheckout))
                    .catch((e: Error) => {
                        console.warn('error', e)
                        swalWithBootstrapButtons.fire(
                            'Falha',
                            'Não foi possível processar o pagamento. Entre em contato com o administrador do sistema',
                            'error'
                        )
                    })
                    .finally(() => setIsOpen(false))
            }
        })
    }

    const renderTable = (data: MensalidadeModel[]) => {
        return (
            <div className="mensalidades-list-page">
                <Helmet>
                    <title>{titlePageTemplate.replace('{0}', 'Mensalidades')}</title>
                </Helmet>
                <LoadingModal IsOpen={isOpen} />
                <div className="table-responsive">
                    <table className="table table-striped" aria-labelledby="tabelLabel">
                        <thead>
                            <tr>
                                <th className="text-center">Data Vencimento</th>
                                <th className="text-center">Data Pagamento</th>
                                <th className="text-center">Valor</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item: MensalidadeModel) => (
                                <tr key={item.IdMensalidade}>
                                    <td className="text-center">
                                        {(item.DtVencimento &&
                                            formatDateInBrazilianStandard(new Date(item.DtVencimento))) ||
                                            ''}
                                    </td>
                                    <td className="text-center">
                                        {(item.DtPagamento &&
                                            formatDateInBrazilianStandard(new Date(item.DtPagamento))) ||
                                            ''}
                                    </td>
                                    <td className="text-center">
                                        {item.ValorTotal && formatCurrencyBR(item.ValorTotal)}
                                    </td>
                                    <td className="text-center">
                                        <h5>
                                            <span
                                                className={`badge rounded-pill ${
                                                    item.IsOverdue
                                                        ? 'bg-danger'
                                                        : item.Status === 'A'
                                                        ? 'bg-warning'
                                                        : 'bg-success'
                                                }`}
                                            >{`${
                                                item.IsOverdue ? 'Vencida' : item.Status === 'A' ? 'Aberta' : 'Paga'
                                            }`}</span>
                                        </h5>
                                    </td>
                                    <td className="text-center">
                                        {item.Status === 'A' && (
                                            <Button onClick={() => handlePayment(item)}>Pagar</Button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    useEffect(() => {
        populateData()
    }, [])

    const contents = state.loading ? (
        <p>
            <em>Loading...</em>
        </p>
    ) : (
        renderTable(state.data)
    )

    return (
        <div>
            <h1 id="tabelLabel" className="textos">
                Histórico de mensalidades
            </h1>
            <p className="textos">Veja abaixo o histórico financeiro completo das suas mensalidades.</p>
            {contents}
        </div>
    )
}
