import './payment-callback.scss'

import React, { PropsWithChildren, useEffect, useRef } from 'react'

import { Link } from 'react-router-dom'
import { homePage } from '../../constants/local-routes'
import { mensalidadePaymentCallback } from '../../constants/api-routes'
import { useAuth } from '../../hooks/use-auth'
import { useLocalStorage } from 'src/hooks/use-local-storage'
import { useParams } from 'react-router-dom'
import { useQuery } from '../../hooks/use-query'

export const PaymentCallback: React.FC<PropsWithChildren> = () => {
    /*
payment_id	ID (identificador) do pagamento do Mercado Pago.
status	Status do pagamento. Ex.: approved para um pagamento aprovado ou pending para um pagamento pendente.
external_reference	Valor enviado no momento da criação da preferência de pagamento.
merchant_order_id	ID (identificador) da ordem de pagamento gerada no Mercado Pago.
    */
    const shouldRun = useRef(true)
    const authContext = useAuth()
    const query = useQuery()
    const { status } = useParams()
    const possibleStatus = { success: 'success', failure: 'failure', pending: 'pending' }

    const paymentIdQueryParamName = 'payment_id'
    const statusQueryParamName = 'status'
    const externalReferenceQueryParamName = 'external_reference'
    const merchantOrderIdQueryParamName = 'merchant_order_id'

    console.log(paymentIdQueryParamName, query.get(paymentIdQueryParamName))
    console.log(statusQueryParamName, query.get(statusQueryParamName))
    console.log(externalReferenceQueryParamName, query.get(externalReferenceQueryParamName))
    console.log(merchantOrderIdQueryParamName, query.get(merchantOrderIdQueryParamName))

    useEffect(() => {
        const token = authContext.AuthData?.Token
        const paymentId = query.get(paymentIdQueryParamName)
        if (paymentId && token && shouldRun.current) {
            shouldRun.current = false
            const payload = new FormData()
            payload.set(paymentIdQueryParamName, paymentId)
            payload.set(statusQueryParamName, query?.get(statusQueryParamName) || '')
            payload.set(externalReferenceQueryParamName, query?.get(externalReferenceQueryParamName) || '')
            payload.set(merchantOrderIdQueryParamName, query?.get(merchantOrderIdQueryParamName) || '')

            fetch(mensalidadePaymentCallback, {
                method: 'post',
                body: payload,
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }
    }, [authContext.AuthData?.Token, query])

    return (
        <div className="payment-callback-page">
            <div className="card text-center container d-flex align-items-center justify-content-center">
                <div className="checkmark-container">
                    {status === possibleStatus.success ? (
                        <i className="checkmark">✓</i>
                    ) : status === possibleStatus.failure ? (
                        <i className="checkmark">!</i>
                    ) : status === possibleStatus.pending ? (
                        <i className="checkmark">!</i>
                    ) : (
                        ''
                    )}
                </div>
                <h1>
                    {status === possibleStatus.success
                        ? 'Sucesso!'
                        : status === possibleStatus.failure
                        ? 'Falha!'
                        : status === possibleStatus.pending
                        ? 'Pendente!'
                        : ''}
                </h1>
                <p>
                    {status === possibleStatus.success
                        ? 'Seu pagamento foi concluido com sucesso!'
                        : status === possibleStatus.failure
                        ? 'Falha ao realizar o pagamento!'
                        : status === possibleStatus.pending
                        ? 'Seu pagamento está pendente'
                        : ''}
                    <br /> <span style={{ color: '#000' }}>ID da transação:</span>
                    <strong>
                        <mark>{query.get(paymentIdQueryParamName)}</mark>
                    </strong>
                </p>
                <Link to={homePage}>Retornar para a pagina inicial</Link>
            </div>
        </div>
    )
}
