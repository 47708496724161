import { formatCapitalizedName, formatFirstAndLastName } from '../utils/string-utils'

import { CarteirinhaBackType } from 'src/types/components/carteirinha'

const CarteirinhaBack: CarteirinhaBackType = ({ Model, Dependentes, ClassName }) => {
    return (
        <div className={`card card--back position-relative ${ClassName || ''}`}>
            {/* <div className="card__strip"></div> */}
            <div className="card__dependents-member mx-3">
                <span style={{ fontSize: '0.8em', display: 'block' }}>Endereço</span>
                {formatCapitalizedName(Model?.Endereco || '') +
                    formatCapitalizedName((Model?.Numero && ` - ${Model.Numero}`) || '') +
                    formatCapitalizedName((Model?.Bairro && ` - ${Model.Bairro}`) || '') +
                    formatCapitalizedName((Model?.Cidade && ` - ${Model.Cidade}`) || '') +
                    ((Model?.Uf && ` - ${Model.Uf}`) || '').toUpperCase()}
            </div>
            {Dependentes && Dependentes.length > 0 && (
                <div className="row">
                    <div className="col-sm-12">
                        <fieldset className="border rounded-3 p-2 mx-3 my-0">
                            <legend style={{ fontSize: '0.8em', margin: 0 }} className="float-none w-auto">
                                Dependentes
                            </legend>
                            <div className="row">
                                <div className="col-sm-6">
                                    {Dependentes.slice(0, 4).map(dep => (
                                        <div key={dep.Id} className="card__dependents-member">
                                            {formatFirstAndLastName(dep.Nome)}
                                        </div>
                                    ))}
                                </div>
                                <div className="col-sm-6 border-start">
                                    {Dependentes.slice(4, 8).map(dep => (
                                        <div key={dep.Id} className="card__dependents-member">
                                            {formatFirstAndLastName(dep.Nome)}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            )}
            <div className="row position-absolute bottom-0 w-100 g-0">
                <div className="col-sm-12 boder">
                    <fieldset className="border rounded-3 p-2 mx-3 my-2">
                        <legend style={{ fontSize: '0.8em' }} className="float-none w-auto px-32">
                            Central de Atendimento
                        </legend>
                        <div className="row g-0 text-center">
                            <div className="col-4">
                                <strong className="d-block h6" style={{ fontSize: '0.6em' }}>
                                    (33) 3261-2149
                                </strong>
                            </div>
                            <div className="col-4">
                                <strong className="d-block h6" style={{ fontSize: '0.6em' }}>
                                    (33) 98407-6477
                                </strong>
                            </div>
                            <div className="col-4">
                                <strong className="d-block h6" style={{ fontSize: '0.6em' }}>
                                    (33) 99949-2948
                                </strong>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
            {/* <div className="card__signature"></div> */}
            {/* <div className="card__ccv">303</div> */}
        </div>
    )
}

export default CarteirinhaBack
