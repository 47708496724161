import './select-profile.scss'

import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import { capitalizeFirstLetter, formatFirstName, formatSureName } from '../../utils/string-utils'
import { homePage, loginPage } from '../../constants/local-routes'
import { useLocation, useNavigate } from 'react-router-dom'

import { Helmet } from 'react-helmet'
import { IProfile } from '../../types/models/i-profile'
import { LoadingModal } from '../../components/loading-modal'
import Swal from 'sweetalert2'
import { titlePageTemplate } from '../../constants/strings'
import { useAuth } from '../../hooks/use-auth'

const SelectProfilePage: FC<PropsWithChildren> = () => {
    const location = useLocation()
    const authContext = useAuth()
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    const [profiles, setProfiles] = useState<IProfile[]>([])
    useEffect(() => {
        if (!authContext || !authContext.AuthData) {
            navigate(loginPage)
        }
        const doAsync = async () => {
            await authContext
                .LoadProfilesAsync()
                .then(d => {
                    const associadoAsProfile: IProfile = {
                        Id: -1,
                        Name: authContext.AuthData?.User.Nome || '',
                        Role: 'Associado',
                        Genre: authContext.AuthData?.User.Sexo || ''
                    }
                    setProfiles([
                        associadoAsProfile,
                        ...d.map(
                            x =>
                                ({
                                    Id: x.Id,
                                    Name: x.Nome,
                                    Role: x.Parentesco,
                                    Genre: x.Sexo
                                } as IProfile)
                        )
                    ])
                })
                .finally(() => setIsOpen(false))
        }
        doAsync()
    }, [authContext, navigate])

    const mapGenreToAvatar = (genre: string | null) => {
        switch ((genre || '').toUpperCase().trim()) {
            case 'M':
                return 'male-avatar-alpha-light.png'
            case 'F':
                return 'female-avatar-alpha-light.png'
            default:
                return 'unknown-avatar-alpha-light.png'
        }
    }

    const handleSetCurrentProfile = (profile: IProfile) => {
        setIsOpen(p => !p)
        authContext
            .SetCurrentProfileAsync(profile.Id)
            .then(() => {
                if (location.state?.from) navigate(location.state.from)
                else navigate(homePage)
            })
            .finally(() => setIsOpen(p => !p))
        //Swal.fire({
        //    title: 'Confirma a seleção do perfil?',
        //    text: `${formatFirstName(profile.Name)} ${formatSureName(profile.Name)}`,
        //    icon: 'warning',
        //    showCancelButton: true,
        //    confirmButtonColor: '#3085d6',
        //    cancelButtonColor: '#d33',
        //    cancelButtonText: 'Cancelar',
        //    confirmButtonText: 'Sim!',
        //    confirmButtonColor: '#38b554'
        //}).then(result => {
        //    /* Read more about isConfirmed, isDenied below */
        //    if (result.isConfirmed) {
        //        setIsOpen(p => !p)
        //        authContext
        //            .SetCurrentProfileAsync(profile.Id)
        //            .then(() => {
        //                if (location.state?.from) navigate(location.state.from)
        //                else navigate(homePage)
        //            })
        //            .finally(() => setIsOpen(p => !p))
        //    }
        //})
    }

    return (
        <div className="select-profile-page">
            <div className="container profile-page">
                <Helmet>
                    <title>{titlePageTemplate.replace('{0}', 'Seleção do perfil')}</title>
                </Helmet>
                <LoadingModal IsOpen={isOpen} />
                <div className="row justify-content-center">
                    <div className="col-md-6 text-center mb-5">
                        <h2 className="heading-section textos">Selecione um perfil abaixo</h2>
                    </div>
                </div>
                <div className="row">
                    {profiles &&
                        profiles.map(item => (
                            <div key={item.Id} className="col-xl-4 col-lg-6 col-md-12">
                                <div
                                    className={`card profile-header ${
                                        (authContext.AuthData?.ProfileId === item.Id && 'selected') || ''
                                    }`}
                                >
                                    <div className="body">
                                        <div className="row">
                                            {/*<div className="col-lg-5 col-md-5 col-12">*/}
                                            {/*    <div className="profile-image float-md-right">*/}
                                            {/*        <img src={`/images/${mapGenreToAvatar(item.Genre)}`} alt="" />{' '}*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-8">
                                                        <h4 className="m-t-0 m-b-0">
                                                            <strong>{formatFirstName(item.Name)}</strong>{' '}
                                                            {formatSureName(item.Name)}
                                                        </h4>
                                                        <span className="job_post">
                                                            {capitalizeFirstLetter(item.Role)}
                                                        </span>
                                                    </div>
                                                    <div className="col-4">
                                                        <button
                                                            disabled={authContext.AuthData?.ProfileId === item.Id}
                                                            className="btn btn-primary btn-round"
                                                            onClick={() => handleSetCurrentProfile(item)}
                                                        >
                                                            {authContext.AuthData?.ProfileId === item.Id
                                                                ? 'Selecionado'
                                                                : 'Selecionar'}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}

export default SelectProfilePage
