/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes: number, si = false, dp = 1) {
    if (typeof bytes !== 'number') return '0'

    const thresh = si ? 1000 : 1024

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B'
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    let u = -1
    const r = 10 ** dp

    do {
        bytes /= thresh
        ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

    return bytes.toFixed(dp) + ' ' + units[u]
}

export const downloadImage = (path: string) => {
    // console.log(path)
    fetch(path, {
        method: 'GET',
        headers: {}
    })
        .then(response => {
            response.arrayBuffer().then(function (buffer) {
                const url = window.URL.createObjectURL(new Blob([buffer]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'imagem.jpg')
                document.body.appendChild(link)
                link.click()
            })
        })
        .catch(err => {
            if (process.env.NODE_ENV !== 'production') {
                console.log(err)
            }
        })
}

export const getImageDimensions = (base64Content: string) => {
    return new Promise<{ width: number | null; height: number | null }>(resolve => {
        const img = new Image()
        img.onerror = () => resolve({ width: null, height: null })
        img.onload = function () {
            // console.warn(`img.width: ${img.width}`)
            // console.warn(`img.height: ${img.height}`)
            resolve({
                width: img.width,
                height: img.height
            })
        }
        img.src = base64Content
    })
}

export const getBase64 = (file: Blob) => {
    return new Promise<string | null>((resolve, reject) => {
        // Make new FileReader
        const reader = new FileReader()

        // Convert the file to base64 text
        reader.addEventListener(
            `load`,
            e => (e.target && resolve(e.target.result as string)) || reject(new Error('INVALID_OUTPUT'))
        )
        reader.addEventListener(`error`, e => reject(e))
        reader.readAsDataURL(file)
    })
}

/**
 * Format bytes as human-readable text.
 *
 * @param megas Number of Megabytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 *
 * @return The represented value from Megabytes to bytes.
 */
export const mbInBytes = (megas: number, si = false) => {
    if (typeof megas !== 'number') return 0
    const thresh = si ? 1000000 : 1048576
    return megas * thresh
}
