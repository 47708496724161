import { pad, stringIsDate } from './string-utils'

import { logger } from '../services/logger-service'
import { range } from './array-utils'

const MINUTES_IN_MILLISECONDS = 60000
const SECONDS_IN_MILLISECONDS = 1000

/**
 * Validate the parameters inside date time operations.
 * @param  {Date} date
 * @param  {number} minutesOrSeconds
 */
const ValidateParams = (date: Date, minutesOrSeconds: number): true | never => {
    if (minutesOrSeconds <= 0) throw new Error('Minutes or Seconds must be positive non zero')
    else if (date == null) throw new Error(`The argument date is null`)
    else return true
}

/**
 * Gets current DateTim in UTC GMT
 */
export const GetUtcNow = (): Date => new Date(new Date().toUTCString())

/**
 * Adds minutes from a given JS Date object
 * @param  {Date} date
 * @param  {number} minutes
 */
export const AddMinutes = (date: Date, minutes: number): Date | never =>
    ValidateParams(date, minutes) && new Date(date.getTime() + minutes * MINUTES_IN_MILLISECONDS)
/**
 * Adds months from a given JS Date object
 * @param  {Date} date
 * @param  {number} months
 */
export const AddMonth = (date: Date, month: number): Date | never => new Date(date.setMonth(date.getMonth() + month))

declare type AddFromNowOptions = {
    days?: number
    hours?: number
    minutes?: number
    seconds?: number
}

const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000
const HOUR_IN_MILLISECONDS = 60 * 60 * 1000
const MINUTE_IN_MILLISECONDS = 60 * 1000

export const AddFromNow = ({ days, hours, minutes, seconds }: AddFromNowOptions) => {
    return new Date(
        Date.now() +
            Math.abs(days || 0) * DAY_IN_MILLISECONDS +
            Math.abs(hours || 0) * HOUR_IN_MILLISECONDS +
            Math.abs(minutes || 0) * MINUTE_IN_MILLISECONDS +
            Math.abs(seconds || 0) * 1000
    )
}
/**
 * Subtract minutes from a given JS Date object
 * @param  {Date} date
 * @param  {number} minutes
 */
export const SubMinutes = (date: Date, minutes: number): Date | never =>
    ValidateParams(date, minutes) && new Date(date.getTime() - minutes * MINUTES_IN_MILLISECONDS)

/**
 * Add seconds from a given JS Date object
 * @param  {Date} date
 * @param  {number} seconds
 */
export const AddSeconds = (date: Date, seconds: number): Date | never =>
    ValidateParams(date, seconds) && new Date(date.getTime() + seconds * SECONDS_IN_MILLISECONDS)

/**
 * Subtract seconds from a given JS Date object
 * @param  {Date} date
 * @param  {number} seconds
 */
export const SubSeconds = (date: Date, seconds: number): Date | never =>
    ValidateParams(date, seconds) && new Date(date.getTime() - seconds * SECONDS_IN_MILLISECONDS)

export const isDate = (value: unknown) => Object.prototype.toString.call(value) === '[object Date]'

export const formatDateInBrazilianStandard = (date: Date) => {
    if (isDate(date)) {
        return `${pad(date.getDate(), 2, '0')}/${pad(date.getMonth() + 1, 2, '0')}/${date.getFullYear()}`
    } else {
        date = new Date(date)
        return `${pad(date.getDate(), 2, '0')}/${pad(date.getMonth() + 1, 2, '0')}/${date.getFullYear()}`
    }
}

export const formatDayMonthInBrazilianStandard = (date: Date) => {
    date = isDate(date) ? date : new Date(date)
    return `${pad(date.getDate(), 2, '0')}/${pad(date.getMonth() + 1, 2, '0')}`
}
export const formatDaysAgo = (date: Date) => {
    date = isDate(date) ? date : new Date(date)
    const now = new Date()
    const difference = now.getTime() - date.getTime()
    const days = Math.ceil(difference / (1000 * 3600 * 24))
    return days <= 1 ? `hoje` : days <= 7 ? `${days} dia${days >= 1 ? 's' : ''} atrás` : ''
}

export const formatTimeInBrazilianStandard = (date: Date) =>
    isDate(date) ? ` ${pad(date.getHours(), 2, '0')}:${pad(date.getMinutes(), 2, '0')}` : date?.toString()

export const formatDateTimeInBrazilianStandard = (date: Date) => {
    if (isDate(date)) {
        return (
            formatDateInBrazilianStandard(date) + ` ${pad(date.getHours(), 2, '0')}:${pad(date.getMinutes(), 2, '0')}`
        )
    } else {
        date = new Date(date)
        return (
            formatDateInBrazilianStandard(date) + ` ${pad(date.getHours(), 2, '0')}:${pad(date.getMinutes(), 2, '0')}`
        )
    }
    // return date?.toString()
}
export const removeTimeFromDate = (dt: Date) => new Date(dt.toDateString())
export const removeTimeFromDateToUTCString = (dt: Date) =>
    `${dt.getFullYear()}-${pad(dt.getMonth() + 1, 2, '0')}-${pad(dt.getDate(), 2, '0')}T00:00:00Z`
export const setTimeFromDateToUTCString = (dt: Date, hour = 0, minute = 0, seconds = 0) =>
    `${dt.getFullYear()}-${pad(dt.getMonth() + 1, 2, '0')}-01T${pad(hour, 2, '0')}:${pad(minute, 2, '0')}:${pad(
        seconds,
        2,
        '0'
    )}Z`

export const getYear = function (date: Date | string) {
    if (isDate(date)) {
        return (date as Date).getFullYear()
    } else {
        const nd = new Date(date)
        return nd.getFullYear()
    }
}

export const getShortYear = function (date: Date | string) {
    if (isDate(date)) {
        return (date as Date).getFullYear().toString().slice(-2)
    } else {
        const nd = new Date(date)
        return nd.getFullYear().toString().slice(-2)
    }
}

export const getMonth = (date: Date | string) => {
    if (isDate(date)) {
        return ((date as Date).getMonth() + 1).toString()
    } else {
        const nd = new Date(date)
        return (nd.getMonth() + 1).toString()
    }
}

export const formatMothShortYear = (date: Date | string) => `${pad(getMonth(date), 2, '0')}/${getShortYear(date)}`
export const formatCompetencia = function (date: any) {
    if (isDate(date)) {
        return `${pad(date.getMonth() + 1, 2, '0')}/${date.getFullYear()}`
    } else {
        date = new Date(date)
        return `${pad(date.getMonth() + 1, 2, '0')}/${date.getFullYear()}`
    }
}

export const formatDate = function (date: any) {
    if (isDate(date)) {
        return `${pad(date.getDate(), 2, '0')}/${pad(date.getMonth() + 1, 2, '0')}/${date.getFullYear()}`
    } else {
        date = new Date(date)
        return `${pad(date.getDate(), 2, '0')}/${pad(date.getMonth() + 1, 2, '0')}/${date.getFullYear()}`
    }
}
export const formatTime = (date: any) => {
    if (isDate(date)) {
        return `${date.getHours()}:${date.getMinutes()}`
    } else {
        date = new Date(date)
        return `${date.getHours()}:${date.getMinutes()}`
    }
}

const daysNames = {
    'pt-br': ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    'en-us': ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
}

const daysShortNames = {
    'pt-br': ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    'en-us': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
}

const monthNames = {
    'pt-br': [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
    ],
    'en-us': [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]
}

export const getDaysName = (dayIndex: number, lang: keyof typeof daysNames = 'pt-br') => daysNames[lang][dayIndex]

export const getDaysShortName = (dayIndex: number, lang: keyof typeof daysShortNames = 'pt-br') =>
    daysShortNames[lang][dayIndex]

const monthShortNames = {
    'pt-br': ['Jan', 'Fev', 'Mar', 'Abr', 'Maio', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    'en-us': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
}
export const getMonthShortName = (monthIndex: number, lang: keyof typeof monthNames = 'pt-br') =>
    monthShortNames[lang][monthIndex]

export const getMonthName = (monthIndex: number, lang: keyof typeof monthNames = 'pt-br') =>
    monthNames[lang][monthIndex]

export const dateInFull = function (date: Date, lang: keyof typeof monthNames = 'pt-br', separator = 'de') {
    if (isDate(date) === false) {
        date = new Date(date)
    }
    const month = getMonthName(date.getMonth(), lang)
    return `${date.getDate()} ${separator} ${month} ${separator} ${date.getFullYear()}`
}

export const DateDiffInDays = (start: Date, end: Date): number => {
    const diffTime = Math.abs(end.getTime() - start.getTime())
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays
}

export const getCurrentYear = () => new Date().getFullYear()
export const getLastYears = (count: number) => range(getCurrentYear() - count, getCurrentYear())
export const getMonthNames = (lang: keyof typeof monthNames = 'pt-br') => monthNames[lang]

// #region Formata data vinda do .net
export const formatDateToString = (date: Date, format: string) => {
    // logger.warn('date', date)
    if (!date) {
        return null
    }
    // Convert to date
    if (stringIsDate(date.toString())) {
        date = new Date(date)
    } else if (isDate(date) === false) {
        date = new Date(date)
    }

    const yyyy = date.getFullYear()
    const M = date.getMonth()
    const d = date.getDate()
    const day = date.getDay()
    const H = date.getHours()
    let h = H
    const pm = h > 12
    if (pm) h = H - 12
    const m = date.getMinutes()
    const s = date.getSeconds()
    const f = date.getMilliseconds()

    const retorno = format
        .replace(/GMT/g, 'GMT') // GMT -> Greenwich Mean Time
        .replace(/yyyy/g, yyyy.toString()) // yyyy -> Year, (e.g. 2015)
        .replace(/yyy/g, yyyy.toString()) // yyy -> Year, (e.g. 2015)
        .replace(/yy/g, yyyy.toString().slice(-2)) // yy -> Year, leading zero (e.g. 2015 would be 15)
        .replace(/y/g, yyyy.toString().slice(-2)) // y -> Year, no leading zero (e.g. 2015 would be 15)
        .replace(/MMMM/g, getMonthName(M)) // MMMM -> Full month name (e.g. December)
        .replace(/MMM/g, getMonthShortName(M)) // MMM -> Abbreviated Month Name (e.g. Dec)
        .replace(/MM/g, pad(M + 1, 2, '0')) // MM -> Month number with leading zero(eg.04)
        .replace(/M/g, (M + 1).toString()) // M -> Month number(eg.3)
        .replace(/dddd/g, getDaysName(day)) // dddd -> Represents the full name of the day (Monday, Tuesday, etc)
        .replace(/ddd/g, getDaysShortName(day)) // ddd -> Represents the abbreviated name of the day (Mon, Tues, Wed, etc)
        .replace(/dd/g, pad(d.toString(), 2, '0')) // dd -> Represents the day of the month as a number from 01 through 31
        .replace(/d/g, d.toString()) // d -> Represents the day of the month as a number from 1 through 31
        .replace(/HH/g, pad(H.toString(), 2, '0')) // HH -> 24-hour clock hour, with a leading 0 (e.g. 22)
        .replace(/H/g, H.toString()) // H -> 24-hour clock hour (e.g. 15)
        .replace(/hh/g, pad(h.toString(), 2, '0')) // hh -> 12-hour clock, with a leading 0 (e.g. 06)
        .replace(/h/g, h.toString()) // h -> 12-hour clock hour (e.g. 4).
        .replace(/mm/g, pad(m.toString(), 2, '0')) // mm -> Minutes with a leading zero
        .replace(/m/g, m.toString()) // m -> Minutes
        .replace(/ss/g, pad(s.toString(), 2, '0')) // ss -> Seconds with leading zero
        .replace(/s/g, s.toString()) // s -> Seconds
        .replace(/fff/g, pad(f.toString(), 3, '0')) // fff ->  Represents the three most significant digits of the seconds' fraction
        .replace(/ff/g, pad(Math.round(f / 10), 2, '0')) // ff -> Represents the two most significant digits of the seconds' fraction in date and time
        .replace(/f/g, Math.round(f / 100).toString()) // f -> Represents the most significant digit of the seconds' fraction
        .replace(/FFF/g, pad(f.toString(), 3, '0')) // FFF ->  Represents the three most significant digits of the seconds' fraction
        .replace(/FF/g, pad(Math.round(f / 10), 2, '0')) // FF -> Represents the two most significant digits of the seconds' fraction in date and time
        .replace(/F/g, Math.round(f / 100).toString()) // F -> Represents the most significant digit of the seconds' fraction
        .replace(/tt/g, pm ? 'PM' : 'AM') // tt -> AM PM (e.g. AM or PM)
        .replace(/t/g, pm ? 'P' : 'A') // t -> Abbreviated AM/PM (e.g. A or P)

    // logger.warn(
    //     `formatDateToString: \n- type: ${typeof date} \n- Data: ${formatDateTimeInBrazilianStandard(
    //         date
    //     )} \n- date: ${date} \n- format: ${format}  \n- retorno: ${retorno}`
    // )

    return retorno
}
// #endregion Formata data vinda do .net
