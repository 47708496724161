import { Modal, Spinner } from 'react-bootstrap'

import { ModalLoadingType } from '../types/components/modal'

export const LoadingModal: ModalLoadingType = ({ IsOpen }) => {
    return (
        <Modal
            show={IsOpen}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Body>
                <div className="col d-flex align-items-center justify-content-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>{' '}
                    <span>{' Aguarde...'}</span>
                </div>
            </Modal.Body>
        </Modal>
    )
}
