import './login.scss'

import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { homePage, selectProfilePage } from '../../constants/local-routes'
import { useLocation, useNavigate } from 'react-router-dom'

import { CredentialsKey } from '../../constants/local-storage-keys'
import { Helmet } from 'react-helmet'
import { HelpCircle } from 'react-feather'
import InputMask from 'react-input-mask'
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger'
import { Tooltip } from 'react-bootstrap'
import { titlePageTemplate } from '../../constants/strings'
import { useAuth } from '../../hooks/use-auth'
import { useLocalStorage } from '../../hooks/use-local-storage'

const LoginPage: FC<PropsWithChildren> = () => {
    const [contract, setContract] = useState('')
    const [username, setUsername] = useState('000.000.000-00')
    const [password, setPassword] = useState('')
    const [isExecuting, setIsExecuting] = useState(false)
    const [errors, setErrors] = useState<Record<string, string>>({})

    const navigate = useNavigate()
    const location = useLocation()
    const [credentials, setCredentials] = useLocalStorage(CredentialsKey)
    const authContext = useAuth()

    useEffect(() => {
        if (process.env.NODE_ENV !== 'production') {
            //setUsername('141.820.256-87')
            setPassword('161250')
            setContract('2457')
        }
    }, [])

    const isValidFields = useCallback(() => {
        const errors: Record<string, string> = {}
        const regIsCPF = /[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}/
        const regIsCNPJ = /[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2}/
        //const isValidUserName = regIsCPF.test(username) || regIsCNPJ.test(username) || false
        //if ((username || '').trim() === '') errors['username'] = 'Usuário é obrigatório'
        //else if (!isValidUserName) errors['username'] = 'Usuário em formato inválido!'
        if (!password) errors['password'] = 'Senha é obrigatória'
        if (!contract) errors['contract'] = 'O número do contrato é obrigatório'
        setErrors(errors)
        if (process.env.NODE_ENV !== 'production') {
            console.log('errors', errors)
        }
        return Object.keys(errors).length === 0
    }, [password, /*username,*/ contract])

    const handleAuthentication = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        //console.log('username', username)
        if (process.env.NODE_ENV !== 'production') {
            console.log('password', password)
            console.log('contract', contract)
        }
        if (!isValidFields()) return
        setIsExecuting(true)
        authContext
            .SignInAsync(username, password, contract)
            .then(data => {
                if (location.state?.from) navigate(location.state?.from)
                else navigate(data.ProfileId && data.ProfileId > 0 ? homePage : selectProfilePage)
            })
            .finally(() => setIsExecuting(false))
    }

    useEffect(() => {
        const isAuthenticated = (authContext && authContext.AuthData) || false
        const isProfileDefined =
            authContext &&
            authContext.AuthData?.ProfileId &&
            (authContext.AuthData.ProfileId === -1 || authContext.AuthData.ProfileId > 0)
        if (isAuthenticated) navigate(isProfileDefined ? homePage : selectProfilePage)
    }, [authContext, navigate])
    const helpText =
        'Data de Nascimento no formato DiaMesAno com dois dígitos. Ex. pessoa que nasceu em 01/04/1997 a senha é 010497.'
    const renderTooltip = useCallback(
        (props: React.HtmlHTMLAttributes<HTMLDivElement>) => (
            <Tooltip id="button-tooltip" {...props}>
                {helpText}
            </Tooltip>
        ),
        [helpText]
    )

    return (
        <section className="ftco-section login-page img" style={{ backgroundImage: 'url(images/login-cover.png)' }}>
            <Helmet>
                <title>{titlePageTemplate.replace('{0}', 'Login')}</title>
            </Helmet>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-10">
                        <div className="wrap d-md-flex">
                            {/*<div className="img" style={{ backgroundImage: 'url(images/login-cover.png)' }}></div>*/}
                            <div className="img"></div>
                            <div className="login-wrap p-4 p-md-5">
                                <div className="d-flex">
                                    <div className="w-100 logo">
                                        <img className="mb-5" src="../images/logo-alpha-side.png" height="80" />
                                        {/*<h3 className="mb-4">Entrar</h3>*/}
                                    </div>
                                </div>
                                <form action="#" className="signin-form">
                                    <div className="form-group mb-3">
                                        <label className="label textos" htmlFor="contract">
                                            Número do Contrato
                                        </label>
                                        <InputMask
                                            name="contract"
                                            type="tel"
                                            className={`form-control ${('contract' in errors && 'is-invalid') || ''}`}
                                            disabled={isExecuting}
                                            required
                                            mask={'99999999'}
                                            maskChar=""
                                            value={contract}
                                            onBlur={() => isValidFields()}
                                            onChange={e => setContract(e.target.value)}
                                        />
                                        {'contract' in errors && (
                                            <div className="invalid-feedback">{errors.contract}</div>
                                        )}
                                    </div>
                                    {/*<div className="form-group mb-3">*/}
                                    {/*    <label className="label" htmlFor="username">*/}
                                    {/*        CPF*/}
                                    {/*    </label>*/}
                                    {/*    <InputMask*/}
                                    {/*        name="username"*/}
                                    {/*        type="tel"*/}
                                    {/*        className={`form-control ${('username' in errors && 'is-invalid') || ''}`}*/}
                                    {/*        placeholder="000.000.000-00"*/}
                                    {/*        disabled={isExecuting}*/}
                                    {/*        required*/}
                                    {/*        //mask={username.length < 15 ? '999.999.999-999' : '99.999.999/0001-99'}*/}
                                    {/*        mask={'999.999.999-99'}*/}
                                    {/*        maskChar=""*/}
                                    {/*        value={username}*/}
                                    {/*        onBlur={() => isValidFields()}*/}
                                    {/*        onChange={e => setUsername(e.target.value)}*/}
                                    {/*    />*/}
                                    {/*    {'username' in errors && (*/}
                                    {/*        <div className="invalid-feedback">{errors.username}</div>*/}
                                    {/*    )}*/}
                                    {/*</div>*/}
                                    <div className="form-group mb-3">
                                        <label className="label textos" htmlFor="password">
                                            Senha
                                        </label>
                                        <OverlayTrigger placement="top" overlay={renderTooltip}>
                                            <HelpCircle
                                                size={16}
                                                className="mr-75"
                                                style={{ marginLeft: '5px', color: '#1575BB', cursor: 'pointer' }}
                                            />
                                        </OverlayTrigger>
                                        <input
                                            type="password"
                                            pattern="[0-9]*"
                                            inputMode="numeric"
                                            disabled={isExecuting}
                                            className={`form-control ${('password' in errors && 'is-invalid') || ''}`}
                                            onBlur={() => isValidFields()}
                                            placeholder="***********"
                                            value={password}
                                            onChange={e => setPassword(e.target.value.trim())}
                                            required
                                        />
                                        {'password' in errors && (
                                            <div className="invalid-feedback">{errors.password}</div>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <button
                                            type="submit"
                                            className="form-control btn btn-primary rounded submit px-3 mt-4"
                                            onClick={handleAuthentication}
                                            disabled={isExecuting}
                                        >
                                            {isExecuting && (
                                                <>
                                                    <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>{' '}
                                                </>
                                            )}
                                            Entrar
                                        </button>
                                    </div>
                                    <div className="form-group d-md-flex">
                                        {/* <div className="w-50 text-left">
                      <label className="checkbox-wrap checkbox-primary mb-0">
                        Lembrar-me
                        <input type="checkbox" />
                        <span className="checkmark"></span>
                      </label>
                    </div> */}
                                        {/* <div className="w-50 text-md-right">
                  <a href="#">Recuperar senha</a>
                </div> */}
                                    </div>
                                </form>
                                {/* <p className="text-center">
              Not a member?{" "}
              <a data-toggle="tab" href="#signup">
                Sign Up
              </a>
            </p> */}
                                <div className="mt-5">
                                    <div className="d-flex w-100">
                                        <span className="social-media d-flex justify-content-start">
                                            <a
                                                href="https://www.instagram.com/grupolajao/"
                                                target="_blank"
                                                className="social-icon d-flex align-items-center justify-content-center "
                                                rel="noreferrer"
                                            >
                                                <span className="fa fa-instagram textos"></span>
                                            </a>
                                            <a
                                                href="https://www.facebook.com/cartaolajao/"
                                                target="_blank"
                                                className="social-icon d-flex align-items-center justify-content-center"
                                                rel="noreferrer"
                                            >
                                                <span className="fa fa-facebook textos"></span>
                                            </a>
                                            <a
                                                href="http://wa.me/553332612149"
                                                target="_blank"
                                                className="social-icon d-flex align-items-center justify-content-center"
                                                rel="noreferrer"
                                            >
                                                <span className="fa fa-whatsapp textos"></span>
                                            </a>
                                        </span>
                                        <span className="copyright-text d-flex justify-content-end textos">{`Cartão Lajão© ${new Date().getFullYear()} Copyright`}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LoginPage
