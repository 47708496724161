/* eslint-disable prettier/prettier */
import './home.scss'

import React, { PropsWithChildren, useEffect, useState } from 'react'

import { AssociadoModel } from 'src/types/models/associado-model'
import { Helmet } from 'react-helmet'
import { IProfile } from '../../types/models/i-profile'
import Swal from 'sweetalert2'
import cn from 'classnames'
import { formatShortName } from '../../utils/string-utils'
import { titlePageTemplate } from '../../constants/strings'
import { useAuth } from '../../hooks/use-auth'

export const Home: React.FC<PropsWithChildren> = () => {
    const [associado, setAssociado] = useState<AssociadoModel | null | undefined>()
    const authContext = useAuth()
    const [profile, setProfile] = useState<IProfile | null | undefined>()

    useEffect(() => {
        const doAsync = async () => {
            authContext?.AuthData && authContext?.AuthData?.User && setAssociado(authContext?.AuthData.User)
            const currentProfile = await authContext.GetCurrentProfileAsync()

            setProfile(currentProfile)

            if (
                currentProfile &&
                authContext?.AuthData &&
                authContext?.AuthData?.User &&
                authContext?.AuthData?.User?.NuOverdue !== 0
            )
                warningHasOverdue(authContext?.AuthData?.User?.NuOverdue || 0)
        }
        doAsync()
    }, [authContext])

    const msgMoreOrEqual3Overdue =
        'Prezado associado, devido ao fato de você possuir mais de 3 mensalidades em aberto, o acesso ao sistema está limitado, favor entrar em contato com o Financeiro do Cartão Lajão através do telefone:  (33) 3261-2149, para regularizar sua situação e voltar a utilizar todos os benefícios. '
    const msgLessThen3Overdue =
        'Prezado associado, você possui mensalidades em atraso. Mantenha suas parcelas em dia, pois caso atinja 3 ou mais o acesso aos benefícios será suspenso.'
    const warningHasOverdue = (nuOverdue: number) => {
        nuOverdue > 0 &&
            Swal.fire({
                title: 'Atenção!',
                text: nuOverdue >= 3 ? msgMoreOrEqual3Overdue : msgLessThen3Overdue,
                icon: 'error',
                confirmButtonText: 'Fechar',
                confirmButtonColor: '#38b554'
            })
    }

    return (
        <div className="home-page">
            <Helmet>
                <title>{titlePageTemplate.replace('{0}', 'Início')}</title>
            </Helmet>
            <h1 className="textos">Olá, {profile && formatShortName(profile?.Name || '')}!</h1>
            <p style={{ display: 'none' }}>
                Bem vinda à área do Associado, aqui você encontra os principais serviços na palma da sua mão.
            </p>

            <div>
                <div className="container">
                    <div className="row d-flex align-items-center justify-content-center">
                        {/* <div className="col-md-4 col-xl-3">
                            <div className="card bg-c-blue order-card">
                                <div className="card-block">
                                    <h6 className="m-b-20">Orders Received</h6>
                                    <h2 className="text-right">
                                        <i className="fa fa-cart-plus f-left"></i>
                                        <span>486</span>
                                    </h2>
                                    <p className="m-b-0">
                                        Completed Orders<span className="f-right">351</span>
                                    </p>
                                </div>
                            </div>
                        </div> */}

                        <div className="col-sm-8 col-md-6 col-lg-5">
                            <div
                                className={cn('card order-card', {
                                    'bg-c-green': associado?.NuOverdue == 0,
                                    'bg-c-pink': associado?.NuOverdue && associado.NuOverdue > 0
                                })}
                            >
                                <div className="card-block">
                                    <h6 className="m-b-20">Plano Atual</h6>
                                    <h4 className="m-b-20 text-center">{associado?.Plano}</h4>
                                    <h2 className="text-right">
                                        <span>R$</span>
                                        <span>{`${associado?.ValorMensalidade},00`}</span>
                                    </h2>
                                    <p className="m-b-0">
                                        {associado?.NuOverdue && associado.NuOverdue > 0 ? (
                                            <>
                                                Você possui mensalidades vencidas em aberto.{' '}
                                                <a
                                                    href="/mensalidades"
                                                    title="Acesse suas mensalidades"
                                                    className="d-block"
                                                >
                                                    Saiba mais
                                                </a>
                                            </>
                                        ) : (
                                            'Parabéns, você está em dia com suas mensalidades!'
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12">
                            <div className="card-block textos">
                                <div className="telefones">
                                    <h3 className="m-b-20 text-center ">Telefones</h3>{' '}
                                </div>
                                <div className="row text-center">
                                    <div className="col-md-4 my-2">
                                        <h5 className="m-b-20">Cartão Lajão</h5>
                                        <h6>(33) 3261-2149</h6>
                                    </div>
                                    <div className="col-md-4 my-2">
                                        <h5 className="m-b-20">Funerária 24hr</h5>
                                        <h6>(33) 99949-2948</h6>
                                        <h6>(33) 98407-6477</h6>
                                    </div>
                                    <div className="col-md-4 my-2">
                                        <h5 className="m-b-20">Clínica Lajão Saúde</h5>
                                        <h6>(33) 3261-3285</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-md-4 col-xl-3">
                            <div className="card bg-c-yellow order-card">
                                <div className="card-block">
                                    <h6 className="m-b-20">Orders Received</h6>
                                    <h2 className="text-right">
                                        <i className="fa fa-refresh f-left"></i>
                                        <span>486</span>
                                    </h2>
                                    <p className="m-b-0">
                                        Completed Orders<span className="f-right">351</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-xl-3">
                            <div className="card bg-c-pink order-card">
                                <div className="card-block">
                                    <h6 className="m-b-20">Orders Received</h6>
                                    <h2 className="text-right">
                                        <i className="fa fa-credit-card f-left"></i>
                                        <span>486</span>
                                    </h2>
                                    <p className="m-b-0">
                                        Completed Orders<span className="f-right">351</span>
                                    </p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
