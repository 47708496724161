import 'src/pages/carteirinha/carteirinha.scss'

import React, { PropsWithChildren, useEffect, useState } from 'react'

import { AssociadoModel } from 'src/types/models/associado-model'
import CarteirinhaBack from 'src/components/carteirinha-back'
import { DependenteModel } from 'src/types/models/dependente-model'
import { LoadingModal } from 'src/components/loading-modal'
import { carteirinhaLoad } from 'src/constants/api-routes'
import { useSearchParams } from 'react-router-dom'

export const CarteirinhaBackPage: React.FC<PropsWithChildren> = () => {
    const [searchParams] = useSearchParams()

    const [isLoading, setIsLoading] = useState(true)
    const [associado, setAssociado] = useState<AssociadoModel | null | undefined>()
    const [dependentes, setDependentes] = useState<DependenteModel[]>([])

    useEffect(() => {
        const token = searchParams.get('token')
        if (token) {
            fetch(carteirinhaLoad, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(res => res.json())
                .then((res: { dependents: DependenteModel[]; user: AssociadoModel }) => {
                    setAssociado(res.user)
                    setDependentes(res.dependents)
                    setIsLoading(false)
                })
        }
    }, [])

    return (
        <>
            <LoadingModal IsOpen={isLoading} />
            {!isLoading && (
                <div className="carteirinha-page back">
                    <div className="outer">
                        <div className="inner">
                            <div className="rotate">
                                {associado && (
                                    <CarteirinhaBack Model={associado} Dependentes={dependentes} ClassName="mobile" />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
