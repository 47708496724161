export const formatCurrencyBR = (value: number | null | undefined, showCurrencyDisplay = 'symbol'): string => {
    const isNone = showCurrencyDisplay === 'none'
    showCurrencyDisplay = isNone ? 'code' : showCurrencyDisplay
    let ret = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        currencyDisplay: showCurrencyDisplay
    }).format(value || 0)
    ret = isNone ? ret.replace(/[a-z]{3}/i, '').trim() : ret
    return ret
}

export const formatNumber = (v: string) => {
    const partA = v.slice(0, 3)
    const partB = v.slice(3)
    return `${partA} ${partB}`
}
