export const authRoute = `${process.env.REACT_APP_PUBLIC_API_URL}/api/auth/login`
export const tokenExpirationLeft = `${process.env.REACT_APP_PUBLIC_API_URL}/api/auth/expiration-left`
export const loadProfiles = `${process.env.REACT_APP_PUBLIC_API_URL}/api/auth/load-profiles`
export const setCurrentProfile = `${process.env.REACT_APP_PUBLIC_API_URL}/api/auth/configure-profile`
export const mensalidadesListPaged = (pageIndex: number) =>
    `${process.env.REACT_APP_PUBLIC_API_URL}/api/mensalidade/list-paged/${pageIndex}`
export const atendimentoMedicoSubmit = `${process.env.REACT_APP_PUBLIC_API_URL}/api/atendimentomedico/submit-atendimento/`
export const atendimentoMedicoConsultas = `${process.env.REACT_APP_PUBLIC_API_URL}/api/atendimentomedico/consulta-options/`
export const atendimentoMedicoExames = `${process.env.REACT_APP_PUBLIC_API_URL}/api/atendimentomedico/exame-options/`
export const mensalidadePaymentCallback = `${process.env.REACT_APP_PUBLIC_API_URL}/api/mensalidade/payment-callback/`
export const mensalidadeProcessPayment = (documentId: string) =>
    `${process.env.REACT_APP_PUBLIC_API_URL}/api/mensalidade/process-payment/${documentId}?isWebVersion=1`
export const carteirinhaLoad = `${process.env.REACT_APP_PUBLIC_API_URL}/api/carteirinha/load`
