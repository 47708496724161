import * as Yup from 'yup'
export const dispatchValidationSync = (validationFn: () => void | never) => {
    try {
        validationFn()
        return true
    } catch (err) {
        if (err instanceof Yup.ValidationError) {
            return false
        }
    }
}

export const dispatchValidationWithValuesSync = (
    schema: Yup.SchemaOf<unknown>,
    fieldName: string,
    ...values: unknown[]
) => {
    return values
        .map(value => {
            try {
                schema.validateSyncAt(fieldName, value, { abortEarly: false })
                return true
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    // if was an validation error
                    return false
                }
            }
            return undefined
        })
        .every(x => x === true)
}
