import { Accept } from 'react-dropzone'

/*
 * References:
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 */
export const images = { 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] } as Accept
export const videoMP4 = { 'video/mp4': ['.mp4'] } as Accept
export const videoMpeg = { 'video/mpeg': ['.mpeg'] } as Accept
export const compressedFileZip = { 'application/zip': ['.zip'] } as Accept
export const compressedFileRar = { 'application/vnd.rar': ['.rar'] } as Accept
export const compressedFile7Zip = { 'application/vnd.rar': ['.rar'] } as Accept
export const documentPdf = { 'application/x-7z-compressed': ['.7z'] } as Accept
