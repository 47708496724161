import React, {
    ForwardRefRenderFunction,
    MutableRefObject,
    forwardRef,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import _, { uniqueId } from 'lodash'

import { FormInputMaskedAltProps } from '../../types/form/input'
import IMask from 'imask'
import ValidationSchemaContext from '../../contexts/form-validation-context'
import cn from 'classnames'
import { dispatchValidationSync } from '../../utils/validation-utils'
import { isDate } from '../../utils/datetime-utils'
import { logger } from '../../services/logger-service'
import { noop } from '../../utils/mixins'
import { useField } from '@unform/core'

const FormInputMaskedAlt: ForwardRefRenderFunction<HTMLInputElement, FormInputMaskedAltProps> = (
    {
        name,
        value,
        label,
        type,
        size,
        description,
        readOnly,
        required,
        disabled,
        maskOptions,
        onAccept,
        useTypedValue,
        returnUnMaskedValue = true,
        children,
        layoutClassName,
        ...rest
    },
    externalRef
) => {
    const { fieldName, defaultValue, registerField, error, clearError } = useField(name)
    /**
     * If you add a value to the input, it will be considered the default
     * This is useful when you have a `<input type="hidden" />`
     *
     * You can also remove it and use the `initialData` or set dynamically.
     */
    const [defaultInputValue, setDefaultInputValue] = useState(value || defaultValue || '')

    const imaskRef = useRef<IMask.InputMask<IMask.AnyMaskedOptions> | null>(null)

    const ariaDescribeId = useMemo(() => uniqueId(), [])
    const schemaContext = useContext(ValidationSchemaContext) // as schema is not required, we need check if it's present

    const isRequired =
        required || // required was defined manually
        (schemaContext !== null &&
            schemaContext.Schema &&
            dispatchValidationSync(() =>
                schemaContext?.Schema?.validateSyncAt(fieldName, undefined, { abortEarly: false })
            ) === false) // required was extracted from schema

    useEffect(() => {
        registerField<string | number | Date>({
            name: fieldName,
            ref: imaskRef,
            getValue: (ref: MutableRefObject<IMask.InputMask<IMask.AnyMaskedOptions> | null>) => {
                const value =
                    (useTypedValue === true
                        ? ref.current?.typedValue
                        : returnUnMaskedValue
                        ? ref.current?.unmaskedValue
                        : ref.current?.masked?.value) || ''

                // logger.warn(`[registerField:getValue] => ${fieldName}`, value)
                return value
            },
            setValue: (
                ref: MutableRefObject<IMask.InputMask<IMask.AnyMaskedOptions> | null>,
                newValue: string | number | Date
            ) => {
                // logger.warn(`[registerField:setValue] => ${fieldName}`, newValue)
                if (ref.current) {
                    if (useTypedValue === true && isDate(newValue)) {
                        ref.current.masked.typedValue = newValue
                        ref.current.updateControl()
                        ref.current.updateValue()
                    } else
                        ref.current.unmaskedValue =
                            (newValue !== null && newValue !== undefined && `${newValue}`) || (newValue as string)
                }
            },
            clearValue: (ref: MutableRefObject<IMask.InputMask<IMask.AnyMaskedOptions> | null>) => {
                logger.warn(`[registerField:clearValue] => ${fieldName}`)
                if (ref.current) ref.current.unmaskedValue = ''
            }
        })
    }, [fieldName, registerField, returnUnMaskedValue, useTypedValue])

    return (
        <div className={cn('form-group', layoutClassName)}>
            {label && (
                <label htmlFor={fieldName}>
                    {label} {isRequired && <span style={{ color: 'red', marginLeft: '5px' }}>*</span>}
                </label>
            )}
            <input
                ref={(node: HTMLInputElement) => {
                    if (node && maskOptions && imaskRef.current === null)
                        imaskRef.current = IMask(node, { ...maskOptions, commit: onAccept || noop })
                    if (node && typeof externalRef === 'function') externalRef(node)
                }}
                name={fieldName}
                type={type || 'text'}
                aria-describedby={ariaDescribeId}
                className={cn('form-control', {
                    'form-control-lg': size === 'large',
                    'form-control-sm': size === 'small',
                    'form-control-plaintext ': readOnly === true,
                    'is-invalid': error
                })}
                defaultValue={defaultInputValue}
                {...rest}
            />
            {children}
            {error && <div className="invalid-feedback">{error}</div>}
            {description && (
                <small id={ariaDescribeId} className={cn('form-text text-muted d-none')}>
                    {description}
                </small>
            )}
        </div>
    )
}

export default forwardRef(FormInputMaskedAlt)
