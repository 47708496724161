import './carteirinha.scss'

import React, { PropsWithChildren, useEffect, useState } from 'react'

import { AssociadoModel } from 'src/types/models/associado-model'
import CarteirinhaFront from 'src/components/carteirinha-front'
import CarteirinhaFrontDependent from 'src/components/carteirinha-front-dependent'
import { DependenteModel } from 'src/types/models/dependente-model'
import { IProfile } from 'src/types/models/i-profile'
import { LoadingModal } from 'src/components/loading-modal'
import { carteirinhaLoad } from 'src/constants/api-routes'
import { useSearchParams } from 'react-router-dom'

type DecodedTokenType = {
    exp: number
    iat: number
    name: string
    nameid: string
    nbf: number
    nu_overdue: string
    profile_id: string
    role: string
}
export const CarteirinhaFrontPage: React.FC<PropsWithChildren> = () => {
    const [searchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(true)
    const [associado, setAssociado] = useState<AssociadoModel | null | undefined>()
    const [profile, setProfile] = useState<IProfile | null>(null)
    const [dependentes, setDependentes] = useState<DependenteModel[]>([])

    useEffect(() => {
        const token = searchParams.get('token')
        if (token) {
            const decodedToken: DecodedTokenType = parseJwt(token)

            fetch(carteirinhaLoad, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(res => res.json())
                .then((res: { dependents: DependenteModel[]; user: AssociadoModel }) => {
                    const { Sexo, Nome } =
                        res.dependents.find(x => x.Id === parseInt(decodedToken.profile_id, 10)) || {}
                    const profile_: IProfile = {
                        Id: parseInt(decodedToken.profile_id, 10),
                        Name: Nome || '',
                        Role: decodedToken.role,
                        Genre: Sexo || ''
                    }
                    setProfile(profile_)
                    setDependentes(res.dependents)
                    setAssociado(res.user)
                    setIsLoading(false)
                })
        }
    }, [])

    function parseJwt(token: string) {
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        const jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                })
                .join('')
        )

        return JSON.parse(jsonPayload)
    }

    return (
        <>
            <LoadingModal IsOpen={isLoading} />
            {!isLoading && (
                <div className="carteirinha-page front">
                    <div className="outer">
                        <div className="inner">
                            <div className="rotate">
                                {(profile?.Role === 'associado' && associado && (
                                    <CarteirinhaFront Model={associado} ClassName="mobile" />
                                )) ||
                                    (associado && profile && (
                                        <CarteirinhaFrontDependent
                                            Model={associado}
                                            Dependentes={dependentes}
                                            Profile={profile}
                                            ClassName="mobile"
                                        />
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
