import { useState } from 'react'

export const useLocalStorage = <T = unknown>(
    keyName: string,
    defaultValue: T | null = null
): [T | null, (newValue: T) => void] => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const value = window.localStorage.getItem(keyName)
            if (value) {
                const typedValue: T = JSON.parse(value)
                return typedValue
            } else {
                window.localStorage.setItem(keyName, JSON.stringify(defaultValue))
                return defaultValue
            }
        } catch (err) {
            return defaultValue
        }
    })
    const setValue = (newValue: T) => {
        try {
            window.localStorage.setItem(keyName, JSON.stringify(newValue))
        } catch (err) {
            /* empty */
        }
        setStoredValue(newValue)
    }
    return [storedValue, setValue]
}
