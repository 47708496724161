import {
    carteirinhaPage,
    homePage,
    loginPage,
    paymentCallback,
    selectProfilePage,
    solicitarAtendimentoMedicoPage
} from '../constants/local-routes'

import { useAuth } from '../hooks/use-auth'
import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    const authContext = useAuth()
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (!authContext.AuthData) {
            // Redirect them to the /login page, but save the current location they were
            // trying to go to when they were redirected. This allows us to send them
            // along to that page after they login, which is a nicer user experience
            // than dropping them off on the home page.
            console.warn('PRECISAMOS NOS AUTENTICAR')
            navigate(loginPage, { state: { from: location } })
        }
        // if the user is authenticated but doesn't select a profile yet
        else if (
            authContext &&
            !authContext.AuthData?.ProfileId &&
            location.pathname.toLowerCase() !== selectProfilePage &&
            location.pathname.toLowerCase() !== paymentCallback
        ) {
            console.warn('PRECISAMOS DEFINIR O PROFILE')
            navigate(selectProfilePage, { state: { from: location } })
        } else if (
            authContext &&
            authContext.AuthData?.User?.NuOverdue &&
            authContext.AuthData?.User.NuOverdue >= 3 &&
            location.pathname.toLowerCase() === solicitarAtendimentoMedicoPage
        ) {
            console.warn('ACESSO RESTRINGIDO')
            navigate(homePage, { state: { from: location } })
        }
    }, [authContext, location, navigate])

    return children
}

export default ProtectedRoute
