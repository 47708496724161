import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react'

import { FormCheckboxType } from 'src/types/form/checkbox'
import cn from 'classnames'
import { logger } from '../../services/logger-service'
import { uniqueId } from 'lodash'
import { useField } from '@unform/core'

const FormCheckbox: FormCheckboxType = ({
    name,
    label,
    type,
    size,
    description,
    readOnly,
    required,
    children,
    value,
    onChange,
    ...rest
}) => {
    const [isChecked, setIsChecked] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)
    const { fieldName, defaultValue, registerField, error } = useField(name)
    const ariaDescribeId = useMemo(() => uniqueId(), [])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked)
        if (typeof onChange === 'function') onChange(e.target.checked)
    }

    useEffect(() => {
        registerField<string | undefined>({
            name: fieldName,
            ref: inputRef,
            getValue(ref: RefObject<HTMLInputElement>) {
                console.warn('(ref.current?.checked', ref.current?.checked)
                const _value = (ref.current?.checked && `${value}`) || undefined
                logger.warn(`[registerField:getValue] => ${fieldName}`, _value)
                return _value
            },
            setValue(ref: RefObject<HTMLInputElement>, newValue: string | undefined) {
                if (ref.current) {
                    setIsChecked(newValue === '1' || newValue?.toString()?.toLowerCase() === 'true' || false)
                    logger.warn(`[registerField:setValue] => ${fieldName}`, value)
                }
            },
            clearValue(ref: RefObject<HTMLInputElement>) {
                if (ref.current) {
                    setIsChecked(false)
                    logger.warn(`[registerField:clearValue] => ${fieldName}`)
                }
            }
        })
    }, [fieldName, registerField])

    return (
        <div className="form-check">
            <input
                value={value}
                //defaultValue={defaultValue}
                aria-describedby={ariaDescribeId}
                className={cn('form-check-input', {
                    'form-control-lg': size === 'large',
                    'form-control-sm': size === 'small',
                    'form-control-plaintext ': readOnly === true,
                    'is-invalid': error
                })}
                type="checkbox"
                name={fieldName}
                ref={inputRef}
                checked={isChecked}
                onChange={handleChange}
                id={ariaDescribeId}
                {...rest}
            />
            <label className="form-check-label" htmlFor={ariaDescribeId}>
                {label}
            </label>
            {children}
        </div>
    )
}

export default FormCheckbox
