export const createId = (length = 16) => {
    let result = ''
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const len = chars.length
    for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * len))
    }
    return result
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {}
