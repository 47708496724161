import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react'

import FormItemLayout from './form-item-layout'
import { FormTextAreaType } from '../../types/form/textarea'
import cn from 'classnames'
import { logger } from '../../services/logger-service'
import { uniqueId } from 'lodash'
import { useField } from '@unform/core'

const FormTextArea: FormTextAreaType = ({
    name,
    label,
    type,
    size,
    description,
    readOnly,
    required,
    children,
    value,
    maxLength,
    displayRemainingCharacters = maxLength && maxLength > 0,
    layoutClassName,
    ...rest
}) => {
    const elementRef = useRef<HTMLTextAreaElement>(null)
    const { fieldName, defaultValue: unformInitialState, registerField, error } = useField(name)
    const defaultValue = value || unformInitialState || ''
    const [currentCharactersLength, setCurrentCharactersLength] = useState<number>(0)
    const ariaDescribeId = useMemo(() => uniqueId(), [])

    useEffect(() => {
        registerField<string | undefined | null>({
            name: fieldName,
            ref: elementRef,
            getValue(ref: RefObject<HTMLInputElement>) {
                const value = ref.current?.value
                // logger.warn(`[registerField:getValue] => ${fieldName}`, value)
                return value
            },
            setValue(ref: RefObject<HTMLInputElement>, newValue) {
                logger.warn(`[registerField:setValue] => ${fieldName}`, newValue || '')
                logger.warn(`[registerField:setValue:ref.current] => ${fieldName}`, ref.current)

                if (ref.current) {
                    ref.current.value = newValue || ''
                    setCurrentCharactersLength(ref.current.value.length)
                    logger.warn(`[registerField:setValue] => ${fieldName}`, ref.current.value)
                }
            },
            clearValue(ref: RefObject<HTMLInputElement>) {
                if (ref.current) {
                    ref.current.value = ''
                    setCurrentCharactersLength(0)
                    logger.warn(`[registerField:clearValue] => ${fieldName}`)
                }
            }
        })
    }, [fieldName, registerField])

    return (
        <FormItemLayout {...{ name, label, required, description, layoutClassName }}>
            <>
                <textarea
                    defaultValue={defaultValue}
                    name={name}
                    ref={elementRef}
                    aria-describedby={ariaDescribeId}
                    className={cn('form-control', {
                        'form-control-lg': size === 'large',
                        'form-control-sm': size === 'small',
                        'form-control-plaintext ': readOnly === true,
                        'is-invalid': error
                    })}
                    onChange={e => setCurrentCharactersLength(e.target.value.length)}
                    {...{ maxLength, ...rest }}
                />
                {displayRemainingCharacters ? (
                    <p style={{ fontSize: '0.8rem' }}>
                        Quantidade de caracteres {currentCharactersLength} de {maxLength || 'ilimitado'}
                    </p>
                ) : null}

                {children}
            </>
        </FormItemLayout>
    )
}

export default FormTextArea
