import 'src/pages/carteirinha/carteirinha.scss'

import React, { PropsWithChildren, useEffect, useState } from 'react'

import { AssociadoModel } from 'src/types/models/associado-model'
import CarteirinhaBack from 'src/components/carteirinha-back'
import CarteirinhaFront from 'src/components/carteirinha-front'
import CarteirinhaFrontDependent from 'src/components/carteirinha-front-dependent'
import { DependenteModel } from 'src/types/models/dependente-model'
import { Helmet } from 'react-helmet'
import { IProfile } from '../../types/models/i-profile'
import { titlePageTemplate } from '../../constants/strings'
import { useAuth } from '../../hooks/use-auth'

// import { formatShortName } from ../../utils/string-utils'
// import { useAuth } from ../../hooks/use-auth'

export const CarteirinhaPage: React.FC<PropsWithChildren> = () => {
    const [profile, setProfile] = useState<IProfile | null | undefined>()
    const [associado, setAssociado] = useState<AssociadoModel | null | undefined>()
    const [dependentes, setDependentes] = useState<DependenteModel[]>([])
    const authContext = useAuth()

    useEffect(() => {
        authContext?.AuthData && authContext?.AuthData?.User && setAssociado(authContext?.AuthData.User)
        authContext?.AuthData && authContext?.AuthData?.Dependents && setDependentes(authContext?.AuthData.Dependents)
        const doAsync = async () => {
            authContext.GetCurrentProfileAsync().then(p => {
                setProfile(p)
            })
        }
        doAsync()
    }, [authContext])

    return (
        <div className="carteirinha-page">
            <Helmet>
                <title>{titlePageTemplate.replace('{0}', 'Carteirinha')}</title>
            </Helmet>
            {profile && (
                // http://nikazawila.github.io/credit-card-template/
                <div className="col">
                    <div className="panel">
                        <div className="row d-flex align-items-center justify-content-center">
                            {(profile && profile.Role === 'associado' && (
                                <>
                                    <div className="col-sm-12 col-lg-6">
                                        {associado && <CarteirinhaFront Model={associado} ClassName="web" />}
                                    </div>
                                    <div className="col-sm-12 col-lg-6">
                                        {associado && (
                                            <CarteirinhaBack
                                                Model={associado}
                                                Dependentes={dependentes}
                                                ClassName="web"
                                            />
                                        )}
                                    </div>
                                </>
                            )) ||
                                (associado && (
                                    <div className="col-sm-12 col-lg-12 d-flex justify-content-center">
                                        <CarteirinhaFrontDependent
                                            Model={associado}
                                            Dependentes={dependentes}
                                            Profile={profile}
                                            ClassName="web"
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
