import './custom.css'

import React, { Component } from 'react'
import { Route, Routes } from 'react-router-dom'
import { carteirinhaBackPage, carteirinhaFrontPage, loginPage, paymentCallback } from './constants/local-routes'

import AppRoutes from './AppRoutes'
import { CarteirinhaBackPage } from './pages/carteirinha/back'
import { CarteirinhaFrontPage } from './pages/carteirinha/front'
import { Layout } from './components/layout'
import Login from './pages/login/index'
import ProtectedRoute from './components/require-auth'
import { ProvideAuth } from './hooks/use-auth'
import { PaymentCallback } from './pages/mensalidades/payment-callback'

export default class App extends Component {
    static displayName = App.name

    render() {
        return (
            <ProvideAuth>
                <Routes>
                    <Route path={loginPage} element={<Login />} />;
                    <Route path={carteirinhaFrontPage} element={<CarteirinhaFrontPage />} />
                    <Route path={carteirinhaBackPage} element={<CarteirinhaBackPage />} />
                    <Route path={paymentCallback} element={<PaymentCallback />} />
                    <Route
                        element={
                            <ProtectedRoute>
                                <Layout />
                            </ProtectedRoute>
                        }
                    >
                        {AppRoutes.map((route, index) => {
                            const { element, ...rest } = route
                            return <Route key={index} {...rest} {...{ element }} />
                        })}
                    </Route>
                </Routes>
            </ProvideAuth>
        )
    }
}
