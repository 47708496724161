import { SchemaOf, mixed, number, object, string } from 'yup'
import { emptyString, isNullOrEmpty } from '../../utils/string-utils'

import { AtendimentoMedico } from 'src/models/atendimento-medico'

const tiposAtendimentoMap = { consulta: '1', exame: '2' }
export const AtendimentoMedicoSchema = (hasExame: boolean): SchemaOf<Partial<AtendimentoMedico>> =>
    object({
        // TipoDeAtendimento: mixed<string>()
        //     .transform(v => (isNullOrEmpty(v) ? undefined : v.toString().trim()))
        //     .required('O tipo de atendimento é obrigatório'),
        IsConsulta: mixed<boolean>()
            .transform(v => !!v)
            .when(['$currentData'], {
                is: (currentData: Partial<AtendimentoMedico>) => {
                    return !currentData.IsExame
                },
                then: schema => schema.required('É necessário selecionar pelo menos uma opção'),
                otherwise: schema => schema.optional()
            }),
        IsExame: mixed<boolean>()
            .transform(v => !!v)
            .when(['$currentData'], {
                is: (currentData: Partial<AtendimentoMedico>) => {
                    return !currentData.IsConsulta
                },
                then: schema => schema.required('É necessário selecionar pelo menos uma opção'),
                otherwise: schema => schema.optional()
            }),
        TelContato: mixed<string>()
            .transform(v => (isNullOrEmpty(v) ? undefined : v.toString().trim()))
            .required('O telefone de contato é obrigatório'),
        // NomeMedicoSolicitante: mixed<string>()
        //     .transform(v => (isNullOrEmpty(v) ? undefined : v.toString().trim()))
        //     .required('O nome do médico solicitante é obrigatório'),
        // CRMMedicoSolicitante: mixed<string>()
        //     .transform(v => (isNullOrEmpty(v) ? undefined : v.toString().trim()))
        //     .required('O CRM do médico solicitante é obrigatório'),
        Observacoes: mixed<string>()
            .transform(v => (isNullOrEmpty(v) ? undefined : v.toString().trim()))
            //.required('A observação é obrigatória')
            .optional(),
        // Procedimento: mixed<string>()
        //     .transform(v => (isNullOrEmpty(v) ? undefined : v.toString().trim()))
        //     .required('O procedimento é obrigatória'),
        AnexoPedidoMedico: mixed<File>()
            .when([], {
                is: () => hasExame,
                then: schema => schema.required('O Anexo é obrigatório'),
                otherwise: schema => schema.optional().notRequired().nullable()
            })
            //.required('O Anexo é obrigatório')
            .transform(file => {
                return (file && file instanceof File && file) || undefined
            })
    }).required()
